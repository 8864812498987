// TODO hỗ trợ nhiều định dạng tiền tệ khác nhau

declare global {
  interface String {
    toCurrency(): string
  }
}

if (!String.prototype.toCurrency) {
  String.prototype.toCurrency = function () {
    const numberFormat = `${this}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return [numberFormat, 'đ'].join(' ')
  }
}

export {}
