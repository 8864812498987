import React from 'react'

import { Grid } from 'antd'

import { BreadcrumbLoanCalculate } from '../components/loanCalculate/components/BreadcrumbLoanCalculate'
import { TableLoanCalculate } from '../components/loanCalculate/components/TableLoanCalculate'

const LoanCalculate = () => {
  const screens = Grid.useBreakpoint()

  return (
    <div className={screens?.md ? 'container-1048' : 'container-1048 bg-white'}>
      {screens?.md && <BreadcrumbLoanCalculate />}
      <TableLoanCalculate />
    </div>
  )
}
export default React.memo(LoanCalculate)
