import { genderOptions, maritalStatusOptions, nationalityOptions } from 'constants/selectOptions'

import { formatBEDate, getLabelOptions } from 'helpers'
import _ from 'lodash'
import { ILoanApplicationBE } from 'types/backend'
import { renderPhoneNumber } from 'utils/string'

const personDecorator = (person?: ILoanApplicationBE) => {
  if (!person) return undefined

  const mappedValues = _.mapValues<ILoanApplicationBE>(
    person,
    (val: any, key: keyof ILoanApplicationBE) => {
      switch (key) {
        case 'birthday':
        case 'issued_on':
          return formatBEDate(val)
        case 'gender':
          return getLabelOptions(genderOptions, val)
        case 'nationality':
          return getLabelOptions(nationalityOptions, val)
        case 'phone':
          return renderPhoneNumber(val)
        case 'marital_status':
          return getLabelOptions(maritalStatusOptions, val)
        default:
          return val
      }
    }
  )

  // @ts-expect-error
  mappedValues.address = (() => {
    const address = [person.address]
    person.ward_name && address.push(person.ward_name)
    person.district_name && address.push(person.district_name)
    person.province_name && address.push(person.province_name)
    return address.filter((el) => Boolean(el)).join(', ')
  })()

  return mappedValues
}

export { personDecorator }
