import { useQuery } from 'react-query'

import { getConfigs } from '../services/getPageConfigs'

const useConfigs = () =>
  useQuery({
    queryKey: ['home-page-configs-v2'],
    queryFn: () => getConfigs(),
    retry: false,
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
    refetchInterval: Number.POSITIVE_INFINITY,
  })

export { useConfigs }
