import React, { ComponentProps, memo } from 'react'

import { Form, Input } from 'antd'
import type { NamePath } from 'antd/lib/form/interface'
import _ from 'lodash'
import moment from 'moment'

const trimValues = (values: any): any => {
  if (!values) return values

  try {
    if (values instanceof moment) return values

    switch (typeof values) {
      case 'string':
        return values.trim()
      case 'object':
        return Array.isArray(values)
          ? values.map((val: any) => trimValues(val))
          : _.mapValues(values, (val: any) => trimValues(val))
      default:
        return values
    }
  } catch {
    return values
  }
}

const FormTextItem: React.FC<
  ComponentProps<typeof Form.Item> & {
    fullName?: NamePath
    inputProps?: Omit<ComponentProps<typeof Input>, 'onBlur'> & {
      onBlur?: (
        e: React.FocusEvent<HTMLInputElement, Element>,
        action: { setValue: (val: string) => void; value: string }
      ) => unknown
    }
  }
> = memo(({ inputProps, fullName, ...itemProps }) => {
  const form = Form.useFormInstance()
  return (
    <div className="form-items">
      <Form.Item {...itemProps}>
        <Input
          {...inputProps}
          onBlur={(e) => {
            const blurName = fullName || itemProps.name
            if (blurName) {
              const currentValues = form?.getFieldValue(blurName)
              form?.setFieldValue(blurName, trimValues(currentValues))
              form?.validateFields([blurName])
            }
            inputProps?.onBlur?.(e, {
              setValue: (val: string) => {
                if (!blurName) return

                form?.setFieldValue(blurName, trimValues(val))
                form?.validateFields([blurName])
              },
              value: blurName ? form?.getFieldValue(blurName) : '',
            })
          }}
        />
      </Form.Item>
    </div>
  )
})

export { FormTextItem }
