import { useQuery } from 'react-query'
import { getDistricts } from 'services/masterDataServices'
import { IDistrict } from 'types/masterDataResponse'

const useDistrictListQuery = (provinceCode: string | number | undefined) => {
  const districtListQuery = useQuery<IDistrict[]>({
    queryKey: ['use-district-list-query', provinceCode],
    queryFn: () => getDistricts(provinceCode as string),
    placeholderData: [],
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: false,
    enabled: Boolean(provinceCode),
    retry: 3,
  })

  return districtListQuery
}

export { useDistrictListQuery }
