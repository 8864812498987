import { useMemo } from 'react'

import useUrlState from '@ahooksjs/use-url-state'
import _ from 'lodash'

const usePickParams = (keys: string[]) => {
  const [urlState] = useUrlState()

  const params = useMemo(() => _.pick(urlState, keys), [JSON.stringify({ keys, urlState })])

  return params
}

export { usePickParams }
