import { useEffect, useState } from 'react'

import { Col, Row, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useSearchParams } from 'react-router-dom'

import {
  calculateTotal,
  formatterNumber,
  mergeOnCell,
  processData,
  sharedOnCell,
} from '../../helpers'
import { useGetDataLoanCalculate } from '../../hooks/useLoanCalculateConfig'
import { TDataLoanCalculateResponse } from '../../types'
import './_style.less'

export const TableLoanCalculate = () => {
  const [dataSource, setDataSource] = useState<TDataLoanCalculateResponse[]>([])
  const [dataTable, setDataTable] = useState<TDataLoanCalculateResponse[]>([])
  const [page, setPage] = useState(1)
  const { mutate } = useGetDataLoanCalculate()
  const [searchParams] = useSearchParams()
  const loan_amount = searchParams.get('loan_amount')
  const loan_time = searchParams.get('loan_time')
  const debt_method = searchParams.get('debt_method')
  const interest_rate = searchParams.get('interest_rate')
  const description = [
    '(*) Lịch thanh toán trên chỉ mang tính chất tham khảo',
    'Lãi suất có thể thay đổi theo quy định của MSB theo từng thời kỳ',
    'Lịch thanh toán gốc và lãi được tạm tính dựa trên giả định 1 tháng có 30 ngày',
    'Nợ gốc và lãi phải trả hàng tháng của Quý khách sẽ căn cứ trên số ngày thực tế, bao gồm trường hợp ngày thanh toán rơi vào ngày nghỉ, ngày lễ và được chuyển sang ngày làm việc kế tiếp.',
  ]

  useEffect(() => {
    if (loan_amount && loan_time && debt_method && interest_rate) {
      mutate(
        { loan_amount, loan_time, debt_method, interest_rate },
        {
          onSuccess: (res) => {
            setDataSource(res?.data)
            setDataTable(processData(res?.data))
          },
        }
      )
    }
    setDataSource([])
  }, [loan_amount, loan_time, debt_method, interest_rate])

  const columns: ColumnsType<TDataLoanCalculateResponse> = [
    {
      title: (
        <span className="loan-calcualte--table--table--row-title bg-gray">Kỳ trả nợ (tháng)</span>
      ),
      dataIndex: 'month',
      key: 'month',
      width: 133,
      align: 'center',
      render: (value, record, index) => (
        <>{record?.type === 'custom' ? <>Sau năm {page}</> : <>{record?.month}</>}</>
      ),
      onCell: (data, index) => sharedOnCell(data, index),
    },
    {
      title: <span className="loan-calcualte--table--table--row-title">Lãi suất tham khảo</span>,
      dataIndex: 'interest_rate',
      key: 'interest_rate',
      width: 153,
      align: 'center',
      render(value, record, index) {
        return <>{record?.interest_rate} %</>
      },
      onCell: (data, index) => mergeOnCell(data, index),
    },
    {
      title: <span className="loan-calcualte--table--table--row-title">Dư nợ gốc</span>,
      dataIndex: 'principal_amount',
      key: 'principal_amount',
      width: 153,
      align: 'right',
      render(value, record, index) {
        return <>{formatterNumber(record?.principal_amount)}</>
      },
      onCell: (data, index) => mergeOnCell(data, index),
    },
    {
      title: <span className="loan-calcualte--table--table--row-title">Phí trả nợ trước hạn</span>,
      dataIndex: 'prepayment_fee',
      key: 'prepayment_fee',
      width: 153,
      align: 'center',
      render(value, record, index) {
        return <>{record?.prepayment_fee} %</>
      },
      onCell: (data, index) => mergeOnCell(data, index),
    },
    {
      title: <span className="loan-calcualte--table--table--row-title">Tiền lãi hàng kỳ</span>,
      dataIndex: 'interest_monthly',
      key: 'interest_monthly',
      width: 153,
      align: 'right',
      render(value, record, index) {
        return (
          <>
            {record?.type === 'custom' ? (
              <> {formatterNumber(String(calculateTotal(dataSource, page, 'interest_monthly')))}</>
            ) : (
              <>{formatterNumber(record?.interest_monthly)}</>
            )}
          </>
        )
      },
    },
    {
      title: <span className="loan-calcualte--table--table--row-title">Tiền gốc hàng kỳ</span>,
      dataIndex: 'principal_amount_monthly',
      key: 'principal_amount_monthly',
      width: 153,
      align: 'right',
      render(value, record, index) {
        return (
          <>
            {record?.type === 'custom' ? (
              <>
                {formatterNumber(
                  String(calculateTotal(dataSource, page, 'principal_amount_monthly'))
                )}
              </>
            ) : (
              <>{formatterNumber(record?.principal_amount_monthly)}</>
            )}
          </>
        )
      },
    },
    {
      title: <span className="loan-calcualte--table--table--row-title">Tiền trả hàng tháng</span>,
      dataIndex: 'total',
      key: 'total',
      width: 153,
      align: 'right',
      render(value, record, index) {
        return (
          <>
            {record?.type === 'custom' ? (
              <>{formatterNumber(String(calculateTotal(dataSource, page, 'total')))}</>
            ) : (
              <>{formatterNumber(record?.total)}</>
            )}
          </>
        )
      },
    },
  ]

  return (
    <div className="loan-calculate--table">
      <div className="loan-calcualte--table--title ">
        Dự trù tài chính với công cụ tính toán lãi vay của MSB
      </div>
      <div className="loan-calcualte--table--table">
        <Table
          className="loan-calcualte--table--table--table"
          dataSource={dataTable}
          columns={columns}
          bordered
          scroll={{ x: 1300 }}
          pagination={{
            pageSize: 13,
            showSizeChanger: false,
            onChange(page) {
              setPage(page)
            },
          }}
          rowClassName={(record, index) => (record?.type === 'custom' ? 'cell-custom' : '')}
        />
      </div>
      <div className="loan-calcualte--table--description my-24 text-caption2-regular">
        <Row>
          <Col md={12} xs={24}>
            {description?.map((text, index) => (
              <p className="m-0" key={index}>
                {text}
              </p>
            ))}{' '}
          </Col>
        </Row>
      </div>
    </div>
  )
}
