import { axiosInstance } from 'services/axiosInstance'
import { TSuccessResponse } from 'types/comonApi'

const getMagnetData = (
  phone: string
): Promise<
  TSuccessResponse<{
    policy_content: string | null
    policy_title: string | null
    sign: number
  }>
> =>
  axiosInstance.get(
    phone ? `/home-loan-be/api/v1/ldp/magnet?phone=${phone}` : '/home-loan-be/api/v1/ldp/magnet'
  )

const postMagnet13 = (
  phone: string
): Promise<
  TSuccessResponse<{
    policy_content: string | null
    policy_title: string | null
    sign: number
  }>
> => axiosInstance.post(`/home-loan-be/api/v1/ldp/magnet/${phone}`)

export { getMagnetData, postMagnet13 }
