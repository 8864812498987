const AvatarSimpleIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.71631 6.37533C7.71631 5.13268 8.72367 4.12533 9.96631 4.12533C11.2089 4.12533 12.2163 5.13268 12.2163 6.37533C12.2163 7.61797 11.2089 8.62533 9.96631 8.62533C8.72367 8.62533 7.71631 7.61797 7.71631 6.37533ZM9.96631 2.79199C7.98729 2.79199 6.38298 4.39631 6.38298 6.37533C6.38298 8.35435 7.98729 9.95866 9.96631 9.95866C11.9453 9.95866 13.5496 8.35435 13.5496 6.37533C13.5496 4.39631 11.9453 2.79199 9.96631 2.79199ZM7.80323 11.625C6.82977 11.625 5.89619 12.0111 5.20785 12.6983C4.51952 13.3855 4.13281 14.3176 4.13281 15.2895V16.7853C4.13281 17.157 4.43464 17.4583 4.80697 17.4583C5.1793 17.4583 5.48113 17.157 5.48113 16.7853V15.2895C5.48113 14.6747 5.72578 14.085 6.16125 13.6502C6.59673 13.2154 7.18737 12.9712 7.80323 12.9712H12.1291C12.7449 12.9712 13.3356 13.2154 13.771 13.6502C14.2065 14.085 14.4512 14.6747 14.4512 15.2895V16.7853C14.4512 17.157 14.753 17.4583 15.1253 17.4583C15.4977 17.4583 15.7995 17.157 15.7995 16.7853V15.2895C15.7995 14.3176 15.4128 13.3855 14.7244 12.6983C14.0361 12.0111 13.1025 11.625 12.1291 11.625H7.80323Z"
      fill="#F4600C"
    />
  </svg>
)

export { AvatarSimpleIcon }
