import { useMemo, useEffect } from 'react'

import useUrlState from '@ahooksjs/use-url-state'

import { usePickParams } from './usePickParams'

const TRACKING_KEYS = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source']
const LOCAL_TRACKING_KEY = 'trackingData'

const useTrackingParamsData = () => {
  const [urlState] = useUrlState()
  const trackingParams = usePickParams(TRACKING_KEYS)
  const isValidTrackingParams = useMemo(() => Object.keys(trackingParams).length !== 0, [urlState])

  useEffect(() => {
    if (!isValidTrackingParams) return

    localStorage.setItem(LOCAL_TRACKING_KEY, JSON.stringify(trackingParams))
  }, [trackingParams, isValidTrackingParams])
}

const getTrackingLocalData = () => {
  const partnerChannelData = JSON.parse(localStorage.getItem(LOCAL_TRACKING_KEY) || 'null')
  return partnerChannelData
}

const removeTrackingLocalData = () => localStorage.removeItem(LOCAL_TRACKING_KEY)

export { useTrackingParamsData, getTrackingLocalData, removeTrackingLocalData }
