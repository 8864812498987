import React from 'react'

import Loading from 'components/Loading'
import { useLoadingState } from 'features/globalLoading'

const GlobalLoadingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { loadingCount } = useLoadingState()

  return (
    <>
      {loadingCount !== 0 && <Loading />}
      {children}
    </>
  )
}

export default React.memo(GlobalLoadingProvider)
