import _ from 'lodash'
import moment from 'moment'
import { axiosInstance } from 'services/axiosInstance'
import { TSuccessResponse } from 'types/comonApi'

import { TDataLoanCalculateRequest, TDataLoanCalculateResponse } from '../types'

const getLoanConfigs = async (): Promise<any> => {
  const [loanCal, interest] = await Promise.all([
    axiosInstance.get('/home-loan-be/api/v1/ldp/loan-cal/config'),
    axiosInstance.get('/home-loan-be/api/v1/ldp/interest-rate'),
  ])

  _.set(loanCal, 'data.interest_rate', _.get(interest, 'data'))
  return loanCal
}

const getDataLoanCalculate = (
  param: TDataLoanCalculateRequest
): Promise<TSuccessResponse<TDataLoanCalculateResponse[]>> =>
  axiosInstance.post('/home-loan-be/api/v1/ldp/loan-cal', param)

const postPriceBracketInfo = (payload: any) =>
  axiosInstance.post('/home-loan-be/api/v1/ldp/loan-cal/get-price-bracket-info', {
    common: {
      request_time: moment().format('yyyyMMDDHHmmss'),
    },
    info: payload,
  })

export { getLoanConfigs, getDataLoanCalculate, postPriceBracketInfo }
