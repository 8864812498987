import { useEffect } from 'react'

import { loadingActions } from 'features/globalLoading'
import { useDispatch } from 'react-redux'

const useGlobalLoading = (loading: boolean = false) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const action = loading ? loadingActions.showLoading() : loadingActions.hideLoading()
    dispatch(action)

    return () => {
      if (!loading) return

      // nếu unmout khi đang loading thì tắt
      dispatch(loadingActions.hideLoading())
    }
  }, [loading])
}

export { useGlobalLoading }
