import { useState } from 'react'

const useModalControl = () => {
  const [isVisible, setVisible] = useState<boolean>(false)
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)

  return {
    isVisible,
    showModal,
    hideModal,
    setVisible,
  }
}

export { useModalControl }
