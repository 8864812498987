const UpIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4343 8.43427C11.7467 8.12185 12.2532 8.12185 12.5656 8.43427L18.5656 14.4343C18.8781 14.7467 18.8781 15.2532 18.5656 15.5656C18.2532 15.8781 17.7467 15.8781 17.4343 15.5656L12 10.1313L6.56564 15.5656C6.25322 15.8781 5.74669 15.8781 5.43427 15.5656C5.12185 15.2532 5.12185 14.7467 5.43427 14.4343L11.4343 8.43427Z"
      fill="#667085"
    />
  </svg>
)

export { UpIcon }
