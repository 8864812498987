import './_styles.less'
import React, { useMemo } from 'react'

import { Col, Row } from 'antd'
import msbLogo from 'assets/images/msb-logo.svg'

import { MailIcon } from './assets/Mail'
import { PhoneIcon } from './assets/Phone'
import { parseFooterData } from './helpers/footerHelpers'
import { useHeaderFooterData } from './hooks/useHeaderFooterData'

const Footer: React.FC = () => {
  const { data, isFetched } = useHeaderFooterData()
  const footerData = useMemo(() => parseFooterData(data), [data])

  if (!isFetched) return <></>
  return (
    <>
      <div className="footer--infos">
        <div>
          <img src={msbLogo} alt="logo" className="logo" />

          <Row gutter={[64, 24]} className="w-100">
            <Col xs={24} md={12}>
              <Row gutter={[16, 8]}>
                <Col xs={24} className="text-subtitle1-bold">
                  Liên hệ
                </Col>

                <Col xs={24}>{footerData.contact_bank}</Col>
                <Col xs={24}>{footerData.contact_address}</Col>

                <Col xs={24}>
                  <a href={`tel:${footerData.contact_phone}`}>
                    <div className="d-flex">
                      <PhoneIcon className="mr-4" />
                      {footerData.contact_phone}
                    </div>
                  </a>
                </Col>

                <Col xs={24}>
                  <div className="d-flex">
                    <MailIcon className="mr-4" />
                    {footerData.contact_email}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={12}>
              <Row gutter={[16, 8]}>
                <Col xs={24} className="text-subtitle1-bold">
                  Mạng xã hội
                </Col>

                {footerData.links.map((link, index) => (
                  <Col xs={8} md={24} key={index}>
                    <a
                      href={link.link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-neutral-500"
                    >
                      <img className="mr-4 footer--network-icon" src={link.icon} alt={link.text} />
                      {link.text}
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <div className="footer--copyright">
        <div className="text-subtitle4-small text-neutral-500">
          Bản quyền © 2021 thuộc về Ngân hàng TMCP Hàng Hải Việt Nam MSB
        </div>
      </div>
    </>
  )
}

export default React.memo(Footer)
