import { useMemo, useEffect } from 'react'

import useUrlState from '@ahooksjs/use-url-state'

import { usePickParams } from './usePickParams'

const PARTNER_KEYS = ['deal_assignee', 'deal_reference_code', 'deal_referral_channel']
const LOCAL_PARTNER_KEY = 'partnerChannelData'

const usePartnerChannelParamsData = () => {
  const [urlState] = useUrlState()
  const partnerChannelParams = usePickParams(PARTNER_KEYS)
  const isValidPartnerChannelParams = useMemo(
    () => Object.keys(partnerChannelParams).length !== 0,
    [urlState]
  )

  useEffect(() => {
    if (!isValidPartnerChannelParams) return

    localStorage.setItem(LOCAL_PARTNER_KEY, JSON.stringify(partnerChannelParams))
  }, [partnerChannelParams, isValidPartnerChannelParams])
}

const getPartnerChannelLocalData = () => {
  const partnerChannelData = JSON.parse(localStorage.getItem(LOCAL_PARTNER_KEY) || 'null')
  return partnerChannelData
}

const removePartnerChannelLocalData = () => localStorage.removeItem(LOCAL_PARTNER_KEY)

export { usePartnerChannelParamsData, getPartnerChannelLocalData, removePartnerChannelLocalData }
