import { ComponentProps, FC, memo, useState } from 'react'

import { Form, Select } from 'antd'
import { DownIcon } from 'features/homepages/assets/DownIcon'
import { UpIcon } from 'features/homepages/assets/UpIcon'
import { selectFilterOption } from 'helpers'

type T_FormSelectItemProps = ComponentProps<typeof Form.Item> & {
  selectProps?: Omit<ComponentProps<typeof Select>, 'suffixIcon' | 'showSearch'>
}

const FormSelectItem: FC<T_FormSelectItemProps> = memo(({ selectProps, ...props }) => {
  const [open, setOpen] = useState(false)

  return (
    <Form.Item {...props}>
      <Select
        filterOption={selectFilterOption}
        {...selectProps}
        open={open}
        onDropdownVisibleChange={setOpen}
        showSearch
        suffixIcon={open ? <UpIcon /> : <DownIcon />}
      />
    </Form.Item>
  )
})

export { FormSelectItem }
