import { useAuthState } from 'features/authentication'
import { useQuery } from 'react-query'

import { getAccountAdviseInfos } from '../services/getAccountAdviseInfos'

const useAccountAdviseInfos = () => {
  const { isAuthenticated, phone } = useAuthState()

  return useQuery({
    queryKey: ['use-account-advise-infos'],
    queryFn: () => getAccountAdviseInfos(phone),
    retry: false,
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
    refetchInterval: Number.POSITIVE_INFINITY,
    enabled: isAuthenticated,
  })
}

export { useAccountAdviseInfos }
