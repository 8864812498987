import { configureStore } from '@reduxjs/toolkit'
import { authReducer, authStoreName } from 'features/authentication'
import { loadingReducer } from 'features/globalLoading'

export const store = configureStore({
  reducer: {
    [authStoreName]: authReducer,
    loading: loadingReducer,
  },
})
