import './_styles.less'
import React, { useCallback, useMemo } from 'react'

import { PATH_NAMES } from 'constants/pathNames'

import { Button, Col, Row, Typography } from 'antd'
import editIcon from 'assets/icons/edit.svg'
import MainCard from 'components/MainCard'
import { useNavigate } from 'react-router-dom'
import { ILoanApplicationBE } from 'types/backend'

import Feedback from '../Feedback'

import { personDecorator } from './helpers'

const PersonInfoCard: React.FC<{
  title: string
  loanId?: string
  feedback?: string | string[]
  person?: ILoanApplicationBE
  rightHeader?: React.ReactNode
  includeRefCode?: boolean
  isEditable?: boolean
}> = ({
  title,
  feedback,
  person,
  loanId,
  rightHeader,
  includeRefCode = true,
  isEditable = true,
}) => {
  const renderPerson = useMemo(() => personDecorator(person), [person])
  const navigate = useNavigate()

  const handleEdit = useCallback(() => {
    if (!loanId) return

    navigate({ pathname: PATH_NAMES.CUSTOMER_PATH, search: `?loanId=${loanId}` })
  }, [loanId])

  return (
    <MainCard
      leftHeader={<Typography className="text-common-title">{title}</Typography>}
      rightHeader={
        rightHeader ||
        (isEditable && (
          <Button
            type="text"
            icon={<img src={editIcon} alt="editIcon" />}
            shape="circle"
            onClick={handleEdit}
          />
        ))
      }
      className="profile-card"
    >
      {feedback && <Feedback msg={feedback} />}

      <div className="details-content">
        <Row>
          <Col xs={24} md={8}>
            Họ và tên
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.full_name}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Ngày sinh
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.birthday}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Giới tính
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.gender}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Quốc tịch
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.nationality}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Số CMND/CCCD
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.id_no}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Ngày cấp
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.issued_on}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Nơi cấp
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.place_of_issue_name}
          </Col>
        </Row>

        {renderPerson?.old_id_no && (
          <Row>
            <Col xs={24} md={8}>
              Số CMND/CCCD cũ
            </Col>
            <Col xs={24} md={16}>
              {renderPerson?.old_id_no}
            </Col>
          </Row>
        )}
        {renderPerson?.old_id_no3 && (
          <Row>
            <Col xs={24} md={8}>
              Hộ chiếu
            </Col>
            <Col xs={24} md={16}>
              {renderPerson?.old_id_no3}
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={24} md={8}>
            Số điện thoại
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.phone}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Email
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.email}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Địa chỉ liên lạc
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.address}
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={8}>
            Tình trạng hôn nhân
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.marital_status}
          </Col>
        </Row>

        {/* <Row>
          <Col xs={24} md={8}>
            Số người phụ thuộc
          </Col>
          <Col xs={24} md={16}>
            {renderPerson?.number_of_dependents}
          </Col>
        </Row> */}

        {includeRefCode && (
          <Row>
            <Col xs={24} md={8}>
              Email cán bộ bán hàng
            </Col>
            <Col xs={24} md={16}>
              {renderPerson?.ref_code}
            </Col>
          </Row>
        )}
      </div>
    </MainCard>
  )
}
export default React.memo(PersonInfoCard)
