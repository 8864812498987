import { ERROR_MESSAGES } from 'constants/errorMessages'

import { Rule } from 'antd/lib/form'
import moment from 'moment'

const required: Rule = {
  validator: (_, value) => {
    if (value === undefined || value === null)
      return Promise.reject(new Error(ERROR_MESSAGES.REQUIRED_FIELD))

    const trimValue = `${value}`.trim()
    return trimValue ? Promise.resolve() : Promise.reject(new Error(ERROR_MESSAGES.REQUIRED_FIELD))
  },
}

const requiredWithMsg: (msg: string) => Rule = (msg) => ({
  validator: (_, value) => {
    if (value === undefined || value === null) return Promise.reject(new Error(msg))

    const trimValue = `${value}`.trim()
    return trimValue ? Promise.resolve() : Promise.reject(new Error(msg))
  },
})

const mobilePhone: Rule = {
  required: true,
  pattern: /^[0][3,5,7,8,9]\d{8}$/,
  message: ERROR_MESSAGES.INVALID_PHONE,
}

const mobilePhoneIncome: Rule = {
  required: true,
  pattern: /^[0]\d{9,19}$/,
  message: ERROR_MESSAGES.INVALID_PHONE_INCOME,
}
const mobilePhoneIncomeOptional: Rule = {
  required: false,
  pattern: /^[0]\d{9,19}$/,
  message: ERROR_MESSAGES.INVALID_PHONE_INCOME,
}

const idNo: Rule[] = [
  { required: true, pattern: /^\d{0,12}$/, message: ERROR_MESSAGES.INVALID_ID_NO },
]

const oldIdNo = (idNoName: string): Rule[] => [
  {
    required: false,
    pattern: /^\d{0,12}$/,
    message: ERROR_MESSAGES.INVALID_OLD_ID_NO,
  },
]

const greaterThan0: Rule = {
  required: false,
  pattern: /^[1-9][0-9]*$/,
  message: ERROR_MESSAGES.GREATER_THAN_0,
}

const pastDay = (message?: string, equal?: boolean): Rule => ({
  validator(_, value) {
    const today = moment(new Date()).startOf('day').toDate().getTime()
    const pickedDate = moment(value).startOf('day').toDate().getTime()

    if (equal && pickedDate <= today) {
      return Promise.resolve()
    }

    if (!equal && pickedDate < today) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(message || 'Vui lòng nhập nhỏ hơn ngày hiện tại'))
  },
})

const futureDay = (message?: string, equal?: boolean): Rule => ({
  validator(_, value) {
    const today = moment(new Date()).startOf('day').toDate().getTime()
    const pickedDate = moment(value).startOf('day').toDate().getTime()

    if (equal && pickedDate >= today) {
      return Promise.resolve()
    }

    if (!equal && pickedDate > today) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(message || 'Vui lòng nhập nhỏ hơn ngày hiện tại'))
  },
})

const greaterDateThanField =
  (fieldName: string, message?: string): Rule =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      const fieldValue = getFieldValue(fieldName) as string
      if (!fieldValue) {
        return Promise.resolve()
      }
      const fieldValueDate = moment(fieldValue).startOf('day').toDate().getTime()
      const pickedDate = moment(value).startOf('day').toDate().getTime()
      if (pickedDate >= fieldValueDate) {
        // eslint-disable-next-line no-template-curly-in-string
        return Promise.reject(new Error(message || ' Vui lòng nhập nhỏ hơn ${label}'))
      }

      return Promise.resolve()
    },
  })
const minValue =
  (minValue: number, equal?: boolean, message?: string): Rule =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (Number.isNaN(value)) {
        return Promise.reject(new Error('Vui lòng nhập đúng định dạng'))
      }

      if (equal && value < minValue) {
        // eslint-disable-next-line no-template-curly-in-string
        return Promise.reject(new Error(message || ' Vui lòng nhập nhỏ hơn hoặc bằng ${label}'))
      }

      if (!equal && value <= minValue) {
        // eslint-disable-next-line no-template-curly-in-string
        return Promise.reject(new Error(message || ' Vui lòng nhập nhỏ hơn ${label}'))
      }

      return Promise.resolve()
    },
  })
const maxValue =
  (maxValue: number, equal?: boolean, message?: string): Rule =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (Number.isNaN(value)) {
        return Promise.reject(new Error('Vui lòng nhập đúng định dạng'))
      }

      if (equal && value > maxValue) {
        // eslint-disable-next-line no-template-curly-in-string
        return Promise.reject(new Error(message || ' Vui lòng nhập lớn hơn hoặc bằng ${label}'))
      }

      if (!equal && value >= maxValue) {
        // eslint-disable-next-line no-template-curly-in-string
        return Promise.reject(new Error(message || ' Vui lòng nhập lớn hơn ${label}'))
      }

      return Promise.resolve()
    },
  })

const commonValidations = {
  required,
  mobilePhone,
  mobilePhoneIncome,
  mobilePhoneIncomeOptional,
  idNo,
  oldIdNo,
  greaterThan0,
  pastDay,
  greaterDateThanField,
  futureDay,
  minValue,
  maxValue,
  requiredWithMsg,
}

export { commonValidations }
