import { axiosInstance } from 'services/axiosInstance'
import { TSuccessResponse } from 'types/comonApi'

import { IHomepage } from '../types'

const getPageConfigs = (type?: string): Promise<any> =>
  axiosInstance.get(`/home-loan-be/api/v1/ldp/home-page/product?code=${type}`)

const getConfigs = (): Promise<TSuccessResponse<IHomepage>> =>
  axiosInstance.get('/home-loan-be/api/v1/ldp/home-page/config')

export { getPageConfigs, getConfigs }
