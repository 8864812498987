const renderPhoneNumber = (val: string | number) =>
  val ? `${val}`.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3') : ''

const numericString = (value: string | number | null | undefined) =>
  value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''

const num2Word = (inputNum?: string | number) => {
  let num = `${inputNum}`.trim()
  if (!inputNum || !num) return ''

  const readGroup = (group: string) => {
    let readDigit = [
      ' Không',
      ' Một',
      ' Hai',
      ' Ba',
      ' Bốn',
      ' Năm',
      ' Sáu',
      ' Bảy',
      ' Tám',
      ' Chín',
    ]
    var temp = ''
    if (group === '000') return ''
    temp = readDigit[parseInt(group.substring(0, 1))] + ' Trăm'
    if (group.substring(1, 2) === '0')
      if (group.substring(2, 3) === '0') return temp
      else {
        temp += ' Linh' + readDigit[parseInt(group.substring(2, 3))]
        return temp
      }
    else temp += readDigit[parseInt(group.substring(1, 2))] + ' Mươi'
    if (group.substring(2, 3) === '5') temp += ' Lăm'
    else if (group.substring(2, 3) !== '0') temp += readDigit[parseInt(group.substring(2, 3))]
    return temp
  }

  if (num === null || num === '') return ''
  let temp = ''
  while (num.length < 18) {
    num = '0' + num
  }
  let g1 = num.substring(0, 3)
  let g2 = num.substring(3, 6)
  let g3 = num.substring(6, 9)
  let g4 = num.substring(9, 12)
  let g5 = num.substring(12, 15)
  let g6 = num.substring(15, 18)

  if (g1 !== '000') {
    temp = readGroup(g1)
    temp += ' Triệu'
  }
  if (g2 !== '000') {
    temp += readGroup(g2)
    temp += ' Nghìn'
  }
  if (g3 !== '000') {
    temp += readGroup(g3)
    temp += ' Tỷ'
  } else if ('' !== temp) {
    temp += ' Tỷ'
  }
  if (g4 !== '000') {
    temp += readGroup(g4)
    temp += ' Triệu'
  }
  if (g5 !== '000') {
    temp += readGroup(g5)
    temp += ' Nghìn'
  }
  temp = temp + readGroup(g6)
  temp = temp.replaceAll('Một Mươi', 'Mười').trim()
  temp = temp.replaceAll('Mười Không', 'Mười').trim()
  temp = temp.replaceAll('Mươi Không', 'Mươi').trim()
  if (temp.indexOf('Không Trăm') === 0) {
    temp = temp.substring(10).trim()
  }
  if (temp.indexOf('Linh') === 0) {
    temp = temp.substring(4).trim()
  }
  temp = temp.trim()
  temp = temp.replaceAll('Mươi Một', 'Mười Một')
  temp = temp.trim()
  let result = temp.substring(0, 1).toUpperCase() + temp.substring(1).toLowerCase()
  return result === '' ? 'Không' : result
}

const unsignedVietnamese = (str: string) => {
  try {
    let copiedString = str
    copiedString = copiedString.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    copiedString = copiedString.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    copiedString = copiedString.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    copiedString = copiedString.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    copiedString = copiedString.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    copiedString = copiedString.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    copiedString = copiedString.replace(/đ/g, 'd')
    copiedString = copiedString.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
    copiedString = copiedString.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
    copiedString = copiedString.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
    copiedString = copiedString.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
    copiedString = copiedString.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
    copiedString = copiedString.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
    copiedString = copiedString.replace(/Đ/g, 'D')
    copiedString = copiedString.replace(/đ/g, 'd')
    copiedString = copiedString.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
    copiedString = copiedString.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư
    return copiedString
  } catch {
    return str
  }
}

export { renderPhoneNumber, num2Word, numericString, unsignedVietnamese }
