import './_feedback.less'
import React from 'react'

import { Typography } from 'antd'
import squareMsg from 'assets/icons/square-msg.svg'

const Feedback: React.FC<{ msg: string | string[] }> = ({ msg }) =>
  msg && msg.length !== 0 ? (
    <div className="feedback">
      <Typography className="text-subtitle2-medium text-neutral-800">
        <img src={squareMsg} alt="squareMsg" /> Phản hồi của ngân hàng
      </Typography>

      {typeof msg === 'string' ? (
        <Typography className="feedback--msg">{msg}</Typography>
      ) : (
        msg.map((content, index) => (
          <Typography className="feedback--msg" key={index}>
            {content}
          </Typography>
        ))
      )}
    </div>
  ) : (
    <></>
  )
export default React.memo(Feedback)
