import './_breadcrumb.less'
import React from 'react'

const Breadcrumb: React.FC<{ items: string[] }> = ({ items }) => <></> // Tạm thời ẩn, yêu cầu ngày 21/03/2023

// (
//   <AntdBreadcrumb
//     separator={<img src={rightIcon} alt="rightIcon" />}
//     className="breadcrumb-component"
//   >
//     {items.map((item, index) => (
//       <AntdBreadcrumb.Item key={index}>{item}</AntdBreadcrumb.Item>
//     ))}
//   </AntdBreadcrumb>
// )

export default React.memo(Breadcrumb)
