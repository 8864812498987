/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react'

import { AdviseProvider } from 'features/adviseModal'
import { MagnetModal } from 'features/authentication/components/MagnetModal'
import GlobalLoadingProvider from 'providers/GlobalLoadingProvider'
import QueryProvider from 'providers/QueryProvider'
import ReduxProvider from 'providers/ReduxProvider'
import RouterProvider from 'providers/RouterProvider'
import { Routes } from 'routes'

const handleLazyLoadFailed = () => {
  const searchKey = 'is-force-reload'
  const params = new URLSearchParams(location.search)
  const isForceReload = (() => {
    try {
      return JSON.parse(params.get(searchKey) || 'false') || false
    } catch {
      return false
    }
  })()

  if (isForceReload) return

  const searchString = `${location.search ? '&' : '?'}${searchKey}=true`
  location.replace([location.href, searchString].join(''))
}

const addChunkLoadListener = () => {
  window.addEventListener('error', (e) => {
    console.log('[[GLOBAL CATCH]]: ', e)

    const lazyLoadMsg = [
      "Uncaught SyntaxError: Unexpected token '<'",
      'Uncaught ReferenceError: chunkId is not defined',
      'Uncaught ChunkLoadError: Loading chunk',
    ]
    const isChunkLoadFailed = /Loading .*chunk .*failed/.test(e.message)
    const islazyLoadFailed = lazyLoadMsg.some((msg) => e.message.startsWith(msg))

    if (isChunkLoadFailed || islazyLoadFailed) {
      handleLazyLoadFailed()
    }
  })
}

const App: React.FC = () => {
  useEffect(addChunkLoadListener, [])

  return (
    <ReduxProvider>
      <QueryProvider>
        <GlobalLoadingProvider>
          <RouterProvider>
            <AdviseProvider>
              <Routes />
              <MagnetModal />
            </AdviseProvider>
          </RouterProvider>
        </GlobalLoadingProvider>
      </QueryProvider>
    </ReduxProvider>
  )
}

export default React.memo(App)
