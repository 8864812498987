import './_mainCard.less'
import React from 'react'

import { Card } from 'antd'
import clsx from 'clsx'

const MainCard: React.FC<
  React.PropsWithChildren & {
    className?: string
    leftHeader?: React.ReactNode
    rightHeader?: React.ReactNode
  }
> = ({ children, className, leftHeader, rightHeader }) => (
  <div className={clsx('main-card-component', className)}>
    {(leftHeader || rightHeader) && (
      <div className="main-card-component--header">
        <span>{leftHeader}</span>
        <span>{rightHeader}</span>
      </div>
    )}

    <Card
      className={clsx(
        'main-card-component--card',
        (leftHeader || rightHeader) && 'main-card-component--card-with-header'
      )}
    >
      {children}
    </Card>
  </div>
)

export default React.memo(MainCard)
