const AvatarIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="20" cy="20" r="20" fill="#D0D5DD" />
    <path
      d="M20 11C17.5454 11 15.5556 13.0147 15.5556 15.5C15.5556 17.9853 17.5454 20 20 20C22.4546 20 24.4444 17.9853 24.4444 15.5C24.4444 13.0147 22.4546 11 20 11Z"
      fill="white"
    />
    <path
      d="M16.4444 21.8C15.2657 21.8 14.1352 22.2741 13.3017 23.118C12.4683 23.9619 12 25.1065 12 26.3V28.1C12 28.5971 12.398 29 12.8889 29H27.1111C27.602 29 28 28.5971 28 28.1V26.3C28 25.1065 27.5317 23.9619 26.6983 23.118C25.8648 22.2741 24.7343 21.8 23.5556 21.8H16.4444Z"
      fill="white"
    />
  </svg>
)

export { AvatarIcon }
