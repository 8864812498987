import { FC, useEffect } from 'react'

import Loading from 'components/Loading'
import { useMagnetData } from 'features/authentication/hooks/useMagnetData'

const Magnetpage: FC = () => {
  const { data } = useMagnetData({ showOnly: true })

  useEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = `
      main.ant-layout-content.content {
        display: flex;
        padding-bottom: 0 !important;
      }
    `
    document.body.appendChild(style)

    return () => {
      document.body.removeChild(style)
    }
  }, [])

  if (!data) return <Loading />

  return <iframe className="tw-w-full tw-h-full" title="ND13" srcDoc={data.policy_content ?? ''} />
}

export { Magnetpage }
