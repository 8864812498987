import React from 'react'

import { Provider } from 'react-redux'
import { store } from 'stores'

const ReduxProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Provider store={store}>{children}</Provider>
)

export default React.memo(ReduxProvider)
