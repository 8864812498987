import { axiosInstance } from './axiosInstance'

const getProvinces = async () => {
  try {
    const { data } = await axiosInstance.get('/home-loan-be/api/v1/ldp/province')
    return data
  } catch (error) {
    throw error
  }
}

const getDistricts = async (provinceCode: string | number) => {
  try {
    const { data } = await axiosInstance.get(
      `/home-loan-be/api/v1/ldp/district/province/${provinceCode}`
    )
    return data
  } catch (error) {
    throw error
  }
}

const getWards = async (provinceCode: string | number, districtCode: string | number) => {
  try {
    const { data } = await axiosInstance.get(
      `/home-loan-be/api/v1/ldp/ward/province/${provinceCode}/district/${districtCode}`
    )
    return data
  } catch (error) {
    throw error
  }
}

const getIssuePlaces = async () => {
  try {
    const { data } = await axiosInstance.get('home-loan-be/api/v1/ldp/id-card/places')
    return data
  } catch (error) {
    throw error
  }
}

export { getProvinces, getDistricts, getWards, getIssuePlaces }
