const ERROR_MESSAGES = {
  REQUIRED_FIELD: 'Trường thông tin bắt buộc nhập',
  INVALID_FORMAT_FIELD: 'Nhập sai định dạng trường thông tin',
  INVALID_PHONE: 'Bạn cần nhập số điện thoại gồm 10 chữ số, bắt đầu bởi số 0',
  ERR_SYS: 'Lỗi hệ thống',
  API_401: 'Phiên đã hết hạn vui lòng đăng nhập lại',
  API_403: 'Bạn không có quyền xem nội dung này',
  INVALID_ID_NO: 'Vui lòng nhập số CMND/CCCD còn hiệu lực. Giới hạn 12 kí tự',
  INVALID_OLD_ID_NO: 'Vui lòng nhập số CMND/CCCD cũ. Giới hạn 12 kí tự',
  GREATER_THAN_0: 'Vui lòng nhập giá trị lớn hơn 0',
  INVALID_PHONE_INCOME: 'Bạn cần nhập số điện thoại từ 10 đến 20 chữ số, bắt đầu bởi số 0',
  BAD_REQUEST: 'Đã có lỗi định dạng, vui lòng kiểm tra lại tất cả các trường thông tin',
}

export { ERROR_MESSAGES }
