// TODO hỗ trợ nhiều định dạng tiền tệ khác nhau

declare global {
  interface Number {
    toCurrency(): string
  }
}

if (!Number.prototype.toCurrency) {
  Number.prototype.toCurrency = function () {
    const [number, decimal] = `${this}`.split('.')
    const numberFormat = [`${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')]
    const numberFormatWithDecimal = decimal ? [numberFormat, decimal].join('.') : numberFormat
    return [numberFormatWithDecimal, 'đ'].join(' ')
  }
}

export {}
