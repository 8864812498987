// otp start
const NUMBER_OF_OTP_INPUT = 6
const OTP_TIME = {
  MAX_TIME_SEND_OTP: 3,
  EXPRIED: 2 * 60 * 1000,
  BLOCK_TIME: 30 * 60 * 1000,
}
// otp end

// login start
const LOGIN_ERROR_MESSSAGES = {
  BLOCK_SEND_OTP: 'Vui lòng chờ %s phút để gửi lại OTP',
  WRONG_OTP: 'Mã OTP chưa đúng. Vui lòng nhập lại OTP',
  WRONG_OTP_MAX_TIMES:
    'Mã OTP nhập sai quá số lần cho phép. Vui lòng chọn Gửi lại OTP để tiếp tục.',
  EXPRIED_OTP: 'OTP hết hiệu lực. Vui lòng chọn Gửi lại OTP để tiếp tục',
  GEN_OTP_ERROR: 'Đã có lỗi xảy ra, vui lòng thực hiện lại thao tác',
}
// login end

const OTP_KEY = 'OTP_COUNT_DOWN_TIME'

export { NUMBER_OF_OTP_INPUT, LOGIN_ERROR_MESSSAGES, OTP_TIME, OTP_KEY }
