import { notification } from 'antd'
import checkCircleSuccessIcon from 'assets/icons/check-circle-success.svg'
import checkCircleIcon from 'assets/icons/check-circle.svg'
import closeCircleIcon from 'assets/icons/close-circle.svg'
import infoIcon from 'assets/icons/info.svg'

interface INotification {
  type?: 'success' | 'info' | 'warning' | 'error'
  message?: string
  description?: string
}

export const openNotification = ({ type = 'info', description }: INotification) => {
  const icon = (() => {
    switch (type) {
      case 'success':
        return checkCircleIcon
      case 'info':
        return checkCircleSuccessIcon
      case 'error':
        return closeCircleIcon
      case 'warning':
        return infoIcon
      default:
        return checkCircleIcon
    }
  })()
  notification.destroy()
  notification[type]({
    message: <></>,
    description,
    duration: 3,
    closeIcon: <></>,
    icon: <img src={icon} alt="icon" />,
  })
}
