import { useLocalStorageState } from 'ahooks'
import { loadingActions } from 'features/globalLoading'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { openNotification } from 'utils/notification'

import { insertAdvise } from '../services'

const useInsertAdviseMutations = (options?: { onSuccess?: () => void; successMsg?: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, setIsBlockAdvise] = useLocalStorageState<boolean | undefined>('IS_USER_BLOCK_ADVISE')
  const dispatch = useDispatch()

  return useMutation(insertAdvise, {
    onMutate: () => {
      dispatch(loadingActions.showLoading())
    },
    onSuccess: () => {
      openNotification({
        type: 'info',
        description: options?.successMsg || 'Bạn đã gửi thông tin tư vấn thành công',
        message: '',
      })
      options?.onSuccess?.()
      setIsBlockAdvise(true)
    },
    onSettled: () => {
      dispatch(loadingActions.hideLoading())
    },
    onError: () => {
      openNotification({
        type: 'error',
        description:
          'Đường truyền mạng không ổn định. Nếu không thao tác được vui lòng tải lại trang (f5).',
        message: 'Hệ thống đang bận. Vui lòng thử lại sau.',
      })
    },
  })
}

export { useInsertAdviseMutations }
