import { TDataLoanCalculateResponse } from '../types'

export const calculateLoanTime = (
  formula: string,
  conditions: any[],
  income: number,
  loan_time: number,
  loan_amount: number,
  monthly_payment_amount: number
) => {
  const formu = formula
    ?.replaceAll('income', String(income))
    ?.replaceAll('loan_time', String(loan_time))
    ?.replaceAll('loan_amount', String(loan_amount))
    ?.replaceAll('monthly_payment_amount', String(monthly_payment_amount))
  try {
    // eslint-disable-next-line no-eval
    let result = 0
    // eslint-disable-next-line no-eval
    const value = Number(eval(formu)).toFixed()
    conditions
      ?.filter((item, index) => index > 0 && item?.type === 'case')
      .forEach((item) => {
        // eslint-disable-next-line no-eval
        if (eval(item?.condition.replaceAll('value', value))) {
          result = item?.value
        }
      })

    return result
  } catch (err) {
    return null
  }
}
export const calculateLoanAmount = (
  formula: string,
  income: number,
  loan_time: number,
  loan_amount: number,
  monthly_payment_amount: number
) => {
  const condition = formula
    ?.replaceAll('income', String(income))
    ?.replaceAll('loan_time', String(loan_time))
    ?.replaceAll('loan_amount', String(loan_amount))
    ?.replaceAll('monthly_payment_amount', String(monthly_payment_amount))
  // eslint-disable-next-line no-eval
  try {
    // eslint-disable-next-line no-eval
    return eval(condition).toFixed()
  } catch (err: any) {
    return null
  }
}

export const formatterNumber = (value?: string) =>
  `${value || ''}`
    .split('.')
    // chỉ tách phần nguyên
    .map((str, index) => (index === 0 ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : str))
    .join('.') + ' đ'

export const sharedOnCell = (_: TDataLoanCalculateResponse, index?: number) => {
  if (_?.type === 'custom') {
    return { colSpan: 4 }
  }
  return {}
}
export const mergeOnCell = (_: TDataLoanCalculateResponse, index?: number) => {
  if (_?.type === 'custom') {
    return { colSpan: 0 }
  }
  return {}
}

export const insertAt = (array: any[], index: number, ...elementsArray: any) => {
  array.splice(index, 0, ...elementsArray)
}

export const calculateTotal = (data: TDataLoanCalculateResponse[], page: number, field: string) =>
  data
    .filter((item, index) => index < page * 12)
    .reduce((accumulator, value) => accumulator + Number(value[field]), 0)

export const processData = (data: TDataLoanCalculateResponse[]): TDataLoanCalculateResponse[] => {
  let dataValue = [...data]
  // nhân 2 data tại các vị trí 13, 25, ...
  Array.from({ length: data?.length }, (value, index) => index)
    .filter((item, index) => index > 0 && index % 12 === 0)
    .reverse()
    .map((value) => insertAt(dataValue, value, { ...data[value], type: 'custom' }))

  if (!dataValue[dataValue.length - 1]?.type) {
    dataValue.push({ ...dataValue[dataValue.length - 1], type: 'custom' })
  }

  return dataValue
}
