import { PATH_NAMES } from 'constants/pathNames'

import { LoginPage, OtpPage } from 'features/authentication'
import { HomePage } from 'features/homepages'
import { MainLayout } from 'layouts'
import { Navigate, useLocation } from 'react-router-dom'

const LoginHandler = ({ to }: { to: string }) => {
  const { pathname, search } = useLocation()
  const prevPath = pathname + search
  return <Navigate to={{ pathname: to, search: `?prevPath=${prevPath}` }} replace />
}

const beforeLoginRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: PATH_NAMES.LOGIN_PATH,
      element: <LoginPage />,
    },
    {
      path: PATH_NAMES.OTP_PATH,
      element: <OtpPage />,
    },
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '*',
      element: <LoginHandler to={PATH_NAMES.LOGIN_PATH} />,
    },
  ],
}

export { beforeLoginRoutes }
