import './_styles.less'
import React, { useEffect, useMemo, useState } from 'react'

import { PATH_NAMES } from 'constants/pathNames'

import { useThrottleEffect } from 'ahooks'
import { Avatar, Button, Divider, Drawer, Dropdown, Grid, Space } from 'antd'
import { ReactComponent as DownIcon } from 'assets/icons/dropdown-select.svg'
import msbLogo from 'assets/images/msb-logo.svg'
import Loading from 'components/Loading'
import { useProfile } from 'features/accountInfos/hooks/useProfile'
import { authActions, useAuthState } from 'features/authentication'
import { useConfigs } from 'features/homepages/hooks/useConfigs'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ModalNotify } from 'utils/modal'
import { pathNameToId } from 'utils/pathNameToId'

import { AvatarIcon } from './assets/Avatar'
import { AvatarSimpleIcon } from './assets/AvatarSimple'
// import { ContactIcon } from './assets/Contact'
import { CloseIcon } from './assets/CloseIcon'
import { FileSearchIcon } from './assets/FileSearch'
import { HamburgerIcon } from './assets/Hamburger'
import { LogoutIcon } from './assets/Logout'
import { parseHeaderData } from './helpers/headerHelpers'
import { useHeaderFooterData } from './hooks/useHeaderFooterData'

const DesktopHeader: React.FC = () => {
  const { data: profile } = useProfile()
  const { isAuthenticated } = useAuthState()
  const { data: dataProduct } = useConfigs()
  const { products } = useMemo(() => parseHeaderData(dataProduct?.data), [dataProduct])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const needRedirectToHomePage = useMemo(
    () => location?.pathname.includes('/tinh-toan-khoan-vay'),
    [location]
  )

  return (
    <>
      <Link to="/">
        <img src={msbLogo} alt="logo" className="logo" />
      </Link>

      <Space className="text-neutral-800">
        <Dropdown
          trigger={['click']}
          menu={{
            items: products,
            onClick: ({ key }) => navigate(key),
          }}
          className="mr-16"
          overlayClassName="home-and-products--dropdown"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={(e) => e.preventDefault()}
            className="text-neutral-800"
            id={pathNameToId('header-list-product')}
          >
            <Space>
              Danh sách sản phẩm
              <DownIcon />
            </Space>
          </a>
        </Dropdown>

        <a href={needRedirectToHomePage ? '/#loan-calculate' : '#loan-calculate'}>
          <Button type="primary" ghost>
            Tính toán khoản vay
          </Button>
        </a>

        <Link to={PATH_NAMES.LOAN_LIST_PATH} id={pathNameToId('header-register-now')}>
          <Button type="primary">Đăng ký ngay</Button>
        </Link>
        <Divider type="vertical" style={{ borderColor: '#98A2B3' }} />

        {isAuthenticated || (
          <Link to={PATH_NAMES.LOAN_LIST_PATH}>
            <span className="pointer text-neutral-800">Theo dõi khoản vay</span>
          </Link>
        )}

        {isAuthenticated && (
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  label: 'Thông tin tài khoản',
                  key: PATH_NAMES.ACCOUNT_PATH,
                  icon: <AvatarSimpleIcon />,
                },
                {
                  label: 'Theo dõi khoản vay',
                  key: PATH_NAMES.LOAN_LIST_PATH,
                  icon: <FileSearchIcon />,
                },
                {
                  label: 'Đăng xuất',
                  key: 'logout',
                  icon: <LogoutIcon />,
                },
              ],
              onClick: ({ key }) => {
                if (key === 'logout') {
                  ModalNotify({
                    title: 'Xác nhận đăng xuất',
                    content: 'Bạn muốn đăng xuất khỏi hệ thống.',
                    onOk: () => {
                      navigate({ pathname: PATH_NAMES.LOGIN_PATH, search: '' })
                      dispatch(authActions.logoutUser())
                    },
                    type: 'warning',
                  })
                  return
                }
                navigate(key)
              },
            }}
            overlayClassName="home-and-
            oducts--dropdown"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={(e) => e.preventDefault()} className="text-neutral-800">
              <Space>
                <Avatar className="avatar" src={<AvatarIcon />} />
                {_.get(profile, 'data.full_name')}
                <DownIcon />
              </Space>
            </a>
          </Dropdown>
        )}
      </Space>
    </>
  )
}

const MobileHeader: React.FC = () => {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const { isAuthenticated } = useAuthState()
  const { data: profile } = useProfile()
  const { data: dataProduct } = useConfigs()
  const { products } = useMemo(() => parseHeaderData(dataProduct?.data), [dataProduct])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const needRedirectToHomePage = useMemo(
    () => location?.pathname.includes('/tinh-toan-khoan-vay'),
    [location]
  )

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  return (
    <>
      <Link to="/">
        <img src={msbLogo} alt="logo" className="logo" />
      </Link>
      <Button type="text" shape="circle" icon={<HamburgerIcon />} onClick={() => setOpen(true)} />
      <Drawer
        title="Danh mục"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        className="home-and-products--drawer"
        closeIcon={<CloseIcon />}
      >
        <div className="flex1">
          {isAuthenticated && (
            <Space className="mt-8 mb-32">
              <Avatar className="avatar" src={<AvatarIcon />} />
              {_.get(profile, 'data.full_name')}
            </Space>
          )}

          <ul className="menu">
            <li>
              Danh sách sản phẩm
              <ul className="sub-menu">
                {(products || []).map((p, index) => (
                  <li key={index}>
                    <Link to={p.key}>{p.label}</Link>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              Chức năng
              <ul className="sub-menu">
                <li>
                  <Link to={PATH_NAMES.ACCOUNT_PATH}>Thông tin tài khoản</Link>
                </li>
                <li>
                  <Link to={PATH_NAMES.LOAN_LIST_PATH}>Theo dõi khoản vay</Link>
                </li>
                <li>
                  <Link
                    to={needRedirectToHomePage ? '/#loan-calculate' : '#loan-calculate'}
                    onClick={() => setOpen(false)}
                  >
                    Công cụ tính toán khoản vay
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li>
            Tính toán khoản vay
            <ul className="sub-menu">
              <li>
                <Link to="/">Công cụ tính toán khoản vay</Link>
              </li>
            </ul>
          </li> */}

            {/* <li>
            Tổng đài
            <ul className="sub-menu">
              <li>
                <ContactIcon />
                19001088
              </li>
            </ul>
          </li> */}
          </ul>
        </div>

        {isAuthenticated && (
          <Button
            type="text"
            ghost
            className="logout-btn"
            block
            onClick={() => {
              ModalNotify({
                title: 'Xác nhận đăng xuất',
                content: 'Bạn muốn đăng xuất khỏi hệ thống.',
                onOk: () => {
                  navigate({ pathname: PATH_NAMES.LOGIN_PATH, search: '' })
                  dispatch(authActions.logoutUser())
                },
                type: 'warning',
              })
            }}
          >
            Đăng xuất
          </Button>
        )}
      </Drawer>
    </>
  )
}

const Header = () => {
  const { md } = Grid.useBreakpoint()
  return md ? <DesktopHeader /> : <MobileHeader />
}

const withReadyData = (Component: React.FC) => () => {
  const { isAuthenticated, uuid } = useAuthState()
  const headerFooterData = useHeaderFooterData()
  const profile = useProfile()
  const [loading, setLoading] = useState(true)

  useThrottleEffect(
    () => {
      setLoading(
        (() => {
          if (isAuthenticated && uuid) return !headerFooterData.isFetched || !profile.isFetched // nếu đã đăng nhập mới chờ profile load xong

          return !headerFooterData.isFetched
        })()
      )
    },
    [isAuthenticated, uuid, headerFooterData.isFetched, profile.isFetched],
    { wait: 500 }
  )

  if (loading) return <Loading bgWhite />
  return <Component />
}

export default React.memo(withReadyData(Header))
