import _ from 'lodash'

const parseFooterData = (
  data: any
): {
  contact_bank: string
  contact_address: string
  contact_email: string
  contact_phone: string
  links: { icon: string; text: string; link: string }[]
} => ({
  contact_bank: _.get(data, 'data.footer.contact.contact_bank') || '',
  contact_address: _.get(data, 'data.footer.contact.contact_address') || '',
  contact_email: _.get(data, 'data.footer.contact.contact_email') || '',
  contact_phone: _.get(data, 'data.footer.contact.contact_phone') || '',
  links: (_.get(data, 'data.footer.network') || []).slice(0, 3),
})

export { parseFooterData }
