import './_loading.less'
import React from 'react'

import { ReactComponent as IconLoading } from 'assets/icons/loading.svg'
import clsx from 'clsx'

const Loading: React.FC<{ bgWhite?: boolean }> = ({ bgWhite }) => (
  <div className={clsx('loading-container', bgWhite && 'loading-container-bg-white ')}>
    <IconLoading />
  </div>
)

export default React.memo(Loading)
