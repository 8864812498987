const marriedPersonOptions = [{ value: 'R87', label: 'Người hôn phối' }]

// giá trị cũ, chỉ phục vụ view data
const relationshipWithoutMarriedOptionsOld = [
  { value: 'FATHER', label: 'Bố ruột/bố nuôi hợp pháp' },
  { value: 'FATHER_IN_LAW', label: 'Bố vợ/chồng' },
  { value: 'MOTHER', label: 'Mẹ ruột/mẹ nuôi hợp pháp' },
  { value: 'MOTHER_IN_LAW', label: 'Mẹ vợ/chồng' },
  { value: 'SIBLINGS', label: 'Anh/chị/em ruột' },
  { value: 'SIBLINGS_IN_LAW', label: 'Anh/chị/em của vợ/chồng' },
  { value: 'SON', label: 'Con ruột/con nuôi hợp pháp' },
  { value: 'STEP_CHILD', label: 'Con riêng' },
  { value: 'HOUSEHOLD_MEM', label: 'Thành viên Hộ gia đình' },
  { value: 'CAPITAL_CONTRIBUTING_MEM', label: 'Thành viên góp vốn HKD/DNTN' },
  { value: 'NEIGHTBOR', label: 'Hàng xóm' },
  { value: 'FRIEND', label: 'Bạn bè' },
  { value: 'FELLOW_SHIP', label: 'Đồng nghiệp' },
  { value: 'OTHERS', label: 'Khác' },
]

// list giá trị mới phục vụ bpm
const relationshipWithoutMarriedOptions = [
  { value: 'R88', label: 'Bố/mẹ ruột' },
  { value: 'R89', label: 'Bố/mẹ nuôi hợp pháp' },
  { value: 'R90', label: 'Con ruột' },
  { value: 'R91', label: 'Con nuôi hợp pháp' },
  { value: 'R92', label: 'Anh/chị/em ruột' },
  { value: 'R93', label: 'Anh rể/em rể/chị dâu/em dâu' },
  { value: 'R94', label: 'Con dâu/con rể' },
  { value: 'R95', label: 'Anh/chị/em của vợ/chồng' },
  { value: 'R96', label: 'Bố mẹ vợ/chồng' },
  { value: 'R97', label: 'Con riêng' },
  { value: 'R98', label: 'Bố dượng/mẹ kế' },
  { value: 'R101', label: 'Đối tượng ủy quyền đại diện phần vốn góp, cổ phần của cá nhân đó' },
  {
    value: 'R102',
    label:
      'Cá nhân cùng cá nhân đó được cùng 1 tổ chức ủy quyền đại diện phần vốn góp, cổ phần tại một tổ chức khác',
  },
  { value: 'R103', label: 'Đối tượng được ủy quyền đại diện phần vốn góp, cổ phần' },
  { value: 'R5', label: 'Công ty/tổ chức liên quan' },
  { value: 'R104', label: 'Anh/chị/em nuôi hợp pháp' },
  { value: 'R85', label: 'Đối tác kinh doanh của MSB' },
  { value: 'OTHERS', label: 'Khác' },
]

const relationshipOptions = [...marriedPersonOptions, ...relationshipWithoutMarriedOptions]

// hiển thị tất cả các options bao gồm cả dữ liệu cũ và mới
const relationshipOptionsFull = [
  ...marriedPersonOptions,
  ...relationshipWithoutMarriedOptions,
  ...relationshipWithoutMarriedOptionsOld,
]

const nationalityOptions = [
  { value: 'VIETNAM', label: 'Việt Nam' },
  { value: 'OTHER', label: 'Khác' },
]

const maritalStatusOptions = [
  { value: 'MARRIED', label: 'Đã kết hôn' },
  { value: 'SINGLE', label: 'Độc thân' },
  { value: 'DIVORCE', label: 'Ly hôn' },
  { value: 'WIDOW', label: 'Goá' },
]

const genderOptions = [
  { value: 'MALE', label: 'Nam' },
  { value: 'FEMALE', label: 'Nữ' },
]

const loanRejectResons = [
  { value: 'Không có nhu cầu vay vốn', label: 'Không có nhu cầu vay vốn' },
  { value: 'Thông tin chưa chính xác', label: 'Thông tin chưa chính xác' },
  { value: 'Thay đổi thông tin hồ sơ', label: 'Thay đổi thông tin hồ sơ' },
]

// list data cũ, chỉ phục vụ view data
const copayerStatusOldObj = {
  FATHER: 'Bố ruột/bố nuôi hợp pháp',
  FATHER_IN_LAW: 'Bố vợ/chồng',
  MOTHER: 'Mẹ ruột/mẹ nuôi hợp pháp',
  MOTHER_IN_LAW: 'Mẹ vợ/chồng',
  SIBLINGS: 'Anh/chị/em ruột',
  SON: 'Con ruột/con nuôi hợp pháp',
  STEP_CHILD: 'Con riêng',
  HOUSEHOLD_MEM: 'Thành viên Hộ gia đình',
  SIBLINGS_IN_LAW: 'Anh/chị/em của vợ/chồng',
  CAPITAL_CONTRIBUTING_MEM: 'Thành viên góp vốn HKD/DNTN',
  OTHERS: 'Khác',
}

const CopayerStatusNewObj = {
  R88: 'Bố/mẹ ruột',
  R89: 'Bố/mẹ nuôi hợp pháp',
  R90: 'Con ruột',
  R91: 'Con nuôi hợp pháp',
  R92: 'Anh/chị/em ruột',
  R93: 'Anh rể/em rể/chị dâu/em dâu',
  R94: 'Con dâu/con rể',
  R95: 'Anh/chị/em của vợ/chồng',
  R96: 'Bố mẹ vợ/chồng',
  R97: 'Con riêng',
  R98: 'Bố dượng/mẹ kế',
  R101: 'Đối tượng ủy quyền đại diện phần vốn góp, cổ phần của cá nhân đó',
  R102: 'Cá nhân cùng cá nhân đó được cùng 1 tổ chức ủy quyền đại diện phần vốn góp, cổ phần tại một tổ chức khác',
  R103: 'Đối tượng được ủy quyền đại diện phần vốn góp, cổ phần',
  R5: 'Công ty/tổ chức liên quan',
  R104: 'Anh/chị/em nuôi hợp pháp',
  R85: 'Đối tác kinh doanh của MSB',
  OTHERS: 'Khác',
}

// bổ sung list mới (phục vụ BPM) thay thế cho CopayerStatus
const { options: CopayerStatusNew } = Object.createSelectOptions({ ...CopayerStatusNewObj })

const { options: CopayerStatusFull } = Object.createSelectOptions({
  ...copayerStatusOldObj,
  ...CopayerStatusNewObj,
})

const creditCardRankEnum = [
  { value: 'PRIMARY_CARD', label: 'Thẻ chính' },
  { value: 'SECONDARY_CARD', label: 'Thẻ phụ' },
]

const creditCardObjectEnum = [
  { value: 'CUSTOMER', label: 'Khách hàng' },
  { value: 'WIFE_HUSBAND', label: 'Vợ/chồng Khách hàng' },
]

const debtDeductionRateEnum = [
  { value: 'MINIMUM_PAYMENT', label: 'Giá trị thanh toán tối thiếu - 5% Tổng dự nợ sao kê' },
  { value: 'STATEMENT_BALANCE', label: 'Tổng dư nợ sao kê - 100% Tổng dư nợ sao kê' },
]

const { options: cardReceivingAddress } = Object.createSelectOptions({
  'Địa chỉ liên hệ': 'Địa chỉ liên hệ',
  'Tại phòng giao dịch phát hành thẻ': 'Tại phòng giao dịch phát hành thẻ',
})

const adviseTimeFrames = [
  { label: 'Sớm nhất có thể', value: 'EARLY_POSSIBLE' },
  { label: '08:00-12:00', value: 'FROM_8_TO_12' },
  { label: '13:00-16:00', value: 'FROM_13_TO_16' },
  { label: '16:00-20:00', value: 'FROM_16_TO_20' },
]

export {
  CopayerStatusNew,
  adviseTimeFrames,
  cardReceivingAddress,
  creditCardObjectEnum,
  creditCardRankEnum,
  debtDeductionRateEnum,
  genderOptions,
  loanRejectResons,
  maritalStatusOptions,
  marriedPersonOptions,
  nationalityOptions,
  relationshipOptions,
  relationshipWithoutMarriedOptions,
  relationshipOptionsFull,
  CopayerStatusFull,
}
