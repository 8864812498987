import { useQuery } from 'react-query'
import { getWards } from 'services/masterDataServices'
import { IWard } from 'types/masterDataResponse'

const useWardListQuery = (
  provinceCode: string | number | undefined,
  districtCode: string | number | undefined
) => {
  const wardListQuery = useQuery<IWard[]>({
    queryKey: ['use-ward-list-query', districtCode],
    queryFn: () => getWards(provinceCode as string, districtCode as string),
    placeholderData: [],
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: false,
    enabled: Boolean(provinceCode) && Boolean(districtCode),
    retry: 3,
  })

  return wardListQuery
}

export { useWardListQuery }
