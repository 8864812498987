/* eslint-disable no-restricted-globals */
import { FC, useCallback, useEffect, useMemo } from 'react'

import { Button, Form, FormInstance } from 'antd'
import { useAuthState } from 'features/authentication'
import { useInsertAdviseMutations } from 'features/homepages/components/advise/hooks'
import { ADVISE } from 'features/homepages/constants'
import { useConfigs } from 'features/homepages/hooks/useConfigs'
import { commonValidations } from 'helpers'
import { useAddressSelect } from 'hooks/useAddressSelect'
import _ from 'lodash'
import { FormHiddenItem } from 'v2/components/FormHiddenItem'
import { FormSelectItem } from 'v2/components/FormSelectItem'
import { FormTextItem } from 'v2/components/FormTextItem'

const AdviseForm: FC<{
  closeModal: () => void
  form: FormInstance<any>
  closeWithConfirm: () => void
}> = ({ closeModal, form, closeWithConfirm }) => {
  const { data } = useConfigs()
  const { provinceList } = useAddressSelect(form)
  const { isAuthenticated, phone } = useAuthState()
  const { mutate: insertAdvise } = useInsertAdviseMutations({
    onSuccess: closeModal,
    successMsg:
      'Cảm ơn bạn đã gửi thông tin tư vấn, MSB sẽ liên hệ với bạn trong thời gian sớm nhất',
  })

  const checkFieldShowable = useCallback(
    (name: keyof typeof ADVISE) =>
      _.get(data, 'data.advises')?.find((i) => i.fieldKey === ADVISE[name])?.isShow,
    [data]
  )

  const productOptions = useMemo(
    () =>
      _.get(data, 'data.products.cardProduct')
        ?.filter((i) => i.isShow)
        ?.map((item) => ({
          label: item.cardTitle,
          value: item?.productCode,
        })) || [],
    [data]
  )

  useEffect(() => {
    form.setFieldValue('customer_phone', phone)
  }, [phone])

  return (
    <Form form={form} name="basic" autoComplete="off" layout={'vertical'} onFinish={insertAdvise}>
      {checkFieldShowable('CUSTOMER_NAME') && (
        <FormTextItem
          label="Họ tên"
          name="customer_name"
          rules={[commonValidations.required]}
          inputProps={{ placeholder: 'Nhập họ và tên của bạn', maxLength: 255 }}
        />
      )}

      {checkFieldShowable('CUSTOMER_PHONE') && (
        <FormTextItem
          label="Số điện thoại"
          name="customer_phone"
          rules={[commonValidations.required, commonValidations.mobilePhone]}
          inputProps={{
            placeholder: 'Nhập số điện thoại',
            maxLength: 10,
            disabled: isAuthenticated,
          }}
        />
      )}

      {checkFieldShowable('PRODUCT') && (
        <>
          <FormSelectItem
            label="Chọn sản phẩm vay cần tư vấn"
            name="product"
            rules={[commonValidations.required]}
            selectProps={{
              placeholder: 'Chọn sản phẩm vay cần tư vấn',
              options: productOptions,
              onChange: (val) =>
                form.setFieldValue(
                  'product_name',
                  productOptions.find((o) => o.value === val)?.label
                ),
            }}
          />

          <FormHiddenItem name="product_name" />
        </>
      )}

      {checkFieldShowable('PROVINCE_CODE') && (
        <>
          <FormSelectItem
            label="Tỉnh/Thành phố"
            name="province_code"
            rules={[commonValidations.required]}
            selectProps={{
              placeholder: 'Chọn Tỉnh/Thành phố',
              options: provinceList?.map((p) => ({ label: p.name, value: p.code })),
            }}
          />
        </>
      )}

      <div className="tw-flex tw-w-full tw-mt-6">
        <Button type="primary" ghost className="tw-flex-1 tw-mr-2" onClick={closeWithConfirm}>
          Bỏ qua
        </Button>

        <Button type="primary" className="tw-flex-1 tw-ml-2" onClick={form.submit}>
          Gửi thông tin
        </Button>
      </div>
    </Form>
  )
}

export { AdviseForm }
