declare global {
  interface String {
    replaceAll(searchValue: RegExp | string, replaceValue: string): string
  }
}

if (!String.prototype.replaceAll) {
  console.log('[[ADD POLYFILL]]: String.prototype.replaceAll')
  // @ts-expect-error
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (searchValue: RegExp | string, replaceValue: string) {
    let target = this
    return target.split(searchValue).join(replaceValue)
  }
}

export {}
