const HamburgerIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.19928 6.0002C2.19928 5.55837 2.55745 5.2002 2.99928 5.2002H20.9993C21.4411 5.2002 21.7993 5.55837 21.7993 6.0002C21.7993 6.44202 21.4411 6.8002 20.9993 6.8002H2.99928C2.55745 6.8002 2.19928 6.44202 2.19928 6.0002ZM2.19928 12.0002C2.19928 11.5584 2.55745 11.2002 2.99928 11.2002H20.9993C21.4411 11.2002 21.7993 11.5584 21.7993 12.0002C21.7993 12.442 21.4411 12.8002 20.9993 12.8002H2.99928C2.55745 12.8002 2.19928 12.442 2.19928 12.0002ZM15.1993 17.2002C15.6411 17.2002 15.9993 17.5584 15.9993 18.0002C15.9993 18.442 15.6411 18.8002 15.1993 18.8002H2.99922C2.55739 18.8002 2.19922 18.442 2.19922 18.0002C2.19922 17.5584 2.55739 17.2002 2.99922 17.2002H15.1993ZM18.7993 18.8002C18.3574 18.8002 17.9993 18.442 17.9993 18.0002C17.9993 17.5584 18.3574 17.2002 18.7993 17.2002H20.9992C21.441 17.2002 21.7992 17.5584 21.7992 18.0002C21.7992 18.442 21.441 18.8002 20.9992 18.8002H18.7993Z"
      fill="#1D2939"
    />
  </svg>
)

export { HamburgerIcon }
