import { useQuery } from 'react-query'
import { getProvinces } from 'services/masterDataServices'
import { IProvince } from 'types/masterDataResponse'

const useProvinceListQuery = () => {
  const provinceListQuery = useQuery<IProvince[]>({
    queryKey: 'use-province-list-query',
    queryFn: getProvinces,
    placeholderData: [],
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: false,
    retry: 3,
  })

  return provinceListQuery
}

export { useProvinceListQuery }
