import './_menu.less'
import React, { useCallback, useEffect } from 'react'

import { PATH_NAMES } from 'constants/pathNames'

import { Button, Grid, Popover, Space } from 'antd'
import hamburgerMenu from 'assets/icons/bars.svg'
import downIcon from 'assets/icons/down.svg'
import upIcon from 'assets/icons/up.svg'
import { authActions, useAuthState } from 'features/authentication'
import { useModalControl } from 'hooks/useModalControl'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { ModalNotify } from 'utils/modal'

const Menu: React.FC = () => {
  const { isVisible, setVisible, hideModal } = useModalControl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuthState()
  const { pathname } = useLocation()
  const screens = Grid.useBreakpoint()

  const logout = useCallback(() => {
    hideModal()

    ModalNotify({
      title: 'Xác nhận đăng xuất',
      content: 'Bạn muốn đăng xuất khỏi hệ thống.',
      onOk: () => {
        navigate({ pathname: PATH_NAMES.LOGIN_PATH, search: '' })
        dispatch(authActions.logoutUser())
      },
      type: 'warning',
    })
  }, [])

  useEffect(() => {
    // đóng menu nếu pathname thay đổi
    hideModal()
  }, [pathname])

  return (
    <>
      {isAuthenticated && (
        <span className="right-header">
          {screens.md && (
            <Link to={PATH_NAMES.LOAN_LIST_PATH}>
              <Button
                type="link"
                className="text-caption1-medium text-neutral-800"
                id="link-loan-list"
              >
                Theo dõi khoản vay
              </Button>
            </Link>
          )}

          <Popover
            content={
              <Space className="w-100" size={8} direction="vertical">
                <Button
                  type="text"
                  className="main-layout-header-menu--button"
                  onClick={() => navigate(PATH_NAMES.ACCOUNT_PATH)}
                >
                  Thông tin khách hàng
                </Button>

                <Button
                  type="text"
                  className="main-layout-header-menu--button"
                  onClick={() => navigate(PATH_NAMES.LOAN_LIST_PATH)}
                >
                  Danh sách khoản vay
                </Button>

                <Button type="text" className="main-layout-header-menu--button" onClick={logout}>
                  Đăng xuất
                </Button>
              </Space>
            }
            open={isVisible}
            placement="bottomRight"
            zIndex={1500}
            onOpenChange={setVisible}
            trigger="click"
            overlayClassName="main-layout-header-menu"
          >
            <Button
              type="link"
              className="text-caption1-medium text-neutral-800"
              icon={screens.md ? <></> : <img src={hamburgerMenu} alt="bars.svg" />}
            >
              {screens.md && (
                <>
                  Tài khoản
                  <img src={isVisible ? upIcon : downIcon} alt="icon" className="ml-8" />
                </>
              )}
            </Button>
          </Popover>
        </span>
      )}
    </>
  )
}

export default React.memo(Menu)
