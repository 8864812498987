import { useGlobalLoading } from 'hooks/useGlobalLoading'
import { useQuery } from 'react-query'

import { getHeaderFooterData } from '../services/getHeaderFooterData'

const useHeaderFooterData = () => {
  const query = useQuery({
    queryKey: ['header-footer-configs'],
    queryFn: () => getHeaderFooterData(),
    retry: false,
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
    refetchInterval: Number.POSITIVE_INFINITY,
  })

  useGlobalLoading(!query.isFetched && query.isFetching)
  return query
}

export { useHeaderFooterData }
