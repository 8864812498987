import _ from 'lodash'

const getOptions = <T extends object>(configs: T) => ({
  options: Object.keys(configs).map((key) => ({
    label: configs[key],
    value: key,
  })),

  code: _.mapValues(configs, (__, key) => key),
})

export const { code: LOAN_PURPOSE_CODE, options: LOAN_PURPOSE_OPTIONS } = getOptions({
  LAND: 'Vay mua bất động sản',
  TIEU_DUNG: 'Vay tiêu dùng',
  XAY_SUA_NHA: 'Vay xây dựng, sửa chữa nhà',
  HO_KINH_DOANH: 'Vay hộ kinh doanh',
})
