import { ComponentProps, FC, memo } from 'react'

import { Form, Input } from 'antd'

const FormHiddenItem: FC<Omit<ComponentProps<typeof Form.Item>, 'hidden'>> = memo((props) => (
  <Form.Item {...props} hidden>
    <Input />
  </Form.Item>
))

export { FormHiddenItem }
