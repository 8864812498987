import './index.less'
import { FC, useEffect, useState } from 'react'

import { PATH_NAMES } from 'constants/pathNames'

import { Button, Checkbox, Modal } from 'antd'
import { ReactComponent as ResultIcon } from 'assets/icons/result-success-icon.svg'
import clsx from 'clsx'
import Loading from 'components/Loading'
import { authActions, useAuthState } from 'features/authentication'
import { useMagnetData } from 'features/authentication/hooks/useMagnetData'
import { postMagnet13 } from 'features/authentication/services/magnetServices'
import { useLocation } from 'react-router-dom'
import { store } from 'stores'

const MagnetModal: FC = () => {
  const { phone, isAuthenticated } = useAuthState()
  const [confirmed, setConfirmed] = useState(false)
  const { data, isFetched, isFetching } = useMagnetData()
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === PATH_NAMES.MAGNET_13 || !isFetched || data?.sign !== 0) return

    setIsOpen(true)
  }, [isFetched])

  useEffect(() => {
    setConfirmed(false)
    setIsOpen(false)

    return () => setConfirmed(false)
  }, [isAuthenticated])

  if (isFetching) return <Loading bgWhite />
  if (!data || pathname === PATH_NAMES.MAGNET_13) return <></>

  return (
    <Modal open={isOpen} className="modal-13" closable={false}>
      <div className="tw-text-center md:tw-px-[56px]">
        <ResultIcon className="tw-h-[48px] tw-w-[48px]" />

        <p className="tw-text-title-bold tw-mt-[24px] tw-mb-0">
          Thư Thông Báo việc xử lý và bảo vệ dữ liệu cá nhân
        </p>
        <p className="tw-text-base-regular !tw-text-gray-500 tw-mb-[20px]">({data.policy_title})</p>
      </div>

      <iframe
        className={clsx(
          'tw-w-full tw-h-[320px] tw-rounded-[8px]',
          'tw-border-[1px] tw-border-solid tw-border-gray-200'
        )}
        title="ND13"
        srcDoc={data.policy_content ?? ''}
      />

      <div className="tw-mt-[28px] tw-mb-[32px]">
        <Checkbox checked={confirmed} onChange={() => setConfirmed((prev) => !prev)}>
          <span className="tw-text-base-regular">
            Tôi đã đọc, hiểu, đồng ý với Bản điều khoản, điều kiện Bảo vệ dữ liệu cá nhân
          </span>
        </Checkbox>
      </div>

      <div className="tw-flex tw-space-x-[12px]">
        <Button
          type="primary"
          ghost
          block
          onClick={() => {
            store.dispatch(authActions.logoutUser())

            setTimeout(() => {
              import('routes').then(({ history }) => {
                history.navigate('/')
              })
            })
          }}
        >
          Không đồng ý
        </Button>

        <Button
          type="primary"
          block
          disabled={!confirmed}
          onClick={() => {
            setIsOpen(false)
            postMagnet13(phone)
          }}
        >
          Đồng ý
        </Button>
      </div>
    </Modal>
  )
}

export { MagnetModal }
