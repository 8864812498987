import { IAuthState } from '../types/authStoreTypes'

const authStoreName = 'authentication'

const defaultAuthState: IAuthState = {
  isAuthenticated: false,
  token: '',
  uuid: '',
  otp_uuid: '',
  phone: '',
  loginType: 'localStorage',
}

const getAuthStateFromLocalStorage = (): IAuthState => {
  try {
    const storegedState = JSON.parse(localStorage.getItem(authStoreName) || '') as IAuthState
    return {
      ...storegedState,
      loginType: 'localStorage',
    }
  } catch {
    return defaultAuthState as IAuthState
  }
}

export { authStoreName, defaultAuthState, getAuthStateFromLocalStorage }
