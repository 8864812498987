import { useEffect } from 'react'

import useUrlState from '@ahooksjs/use-url-state'
import { useProfile } from 'features/accountInfos/hooks/useProfile'
import { useAuthState } from 'features/authentication'
import { usePartnerChannelParamsData } from 'hooks/usePartnerChannelData'
import { useTrackingParamsData } from 'hooks/useTrackingData'
import _ from 'lodash'
import { Location, NavigateFunction, useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { openNotification } from 'utils/notification'

import { afterLoginRoutes } from './afterLoginRoutes'
import { beforeLoginRoutes } from './beforeLoginRoutes'
import { homeAndProductsRoutes } from './homeAndProductsRoutes'

const history: { navigate: NavigateFunction; location: Location } = {
  navigate: () => {},
  // @ts-expect-error
  history: {},
}

const Routes = () => {
  useTrackingParamsData()
  usePartnerChannelParamsData()
  const { isAuthenticated, loginType } = useAuthState()
  const navigate = useNavigate()
  history.navigate = navigate
  history.location = useLocation()
  const [{ prevPath }] = useUrlState()
  const { data: profile } = useProfile()

  useEffect(() => {
    const fullName = _.get(profile, 'data.full_name')
    if (!fullName || loginType !== 'login') return

    // chao khac hang khi dang nhap
    openNotification({ type: 'info', description: `Xin chào ${fullName}` })
  }, [profile?.data.full_name, loginType])

  useEffect(() => {
    if (!isAuthenticated) return
    // neu vua confirm OTP thi isAuthenticated thay doi va co gia tri true thi check prevPath, neu co prevPath thi navigate toi prevPath
    if (!prevPath) return

    navigate(prevPath)
  }, [isAuthenticated])

  return useRoutes([homeAndProductsRoutes, isAuthenticated ? afterLoginRoutes : beforeLoginRoutes])
}

export { history, Routes }
