import { ERROR_MESSAGES } from 'constants/errorMessages'

import axios, { AxiosInstance, AxiosError } from 'axios'
import { authActions } from 'features/authentication'
import { getAuthStateFromLocalStorage } from 'features/authentication/helpers'
import { trymValues } from 'helpers'
import { get } from 'lodash'
import { store } from 'stores'
import { openNotification } from 'utils/notification'

const interceptors = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => response?.data,
    (error: AxiosError) => {
      console.log('[AXIOS - ERROR]', error)

      const axiosErrorCode = get(error, 'code')
      const httpErrorCode = get(error, 'response.status')
      const beDefineErrors = (get(error, 'response.data.errors') || []) as { code: number }[]
      const isBadRequest = beDefineErrors.find((e) => get(e, 'code') === 400001)

      if (isBadRequest) {
        openNotification({
          type: 'error',
          description: ERROR_MESSAGES.BAD_REQUEST,
        })
        return
      }

      if (axiosErrorCode === 'ERR_SYS') {
        openNotification({
          type: 'warning',
          description: ERROR_MESSAGES.ERR_SYS,
        })
        return
      }

      switch (httpErrorCode) {
        case 401:
          store.dispatch(authActions.axiosLogout())
          openNotification({
            type: 'warning',
            description: ERROR_MESSAGES.API_401,
          })
          return
        case 403:
          openNotification({
            type: 'warning',
            description: ERROR_MESSAGES.API_403,
          })
          return
        default:
          return Promise.reject(error)
      }
    }
  )

  instance.interceptors.request.use(async (config) => {
    const customConfigs = { ...config }

    // auto load token
    const authInfo = getAuthStateFromLocalStorage()
    if (authInfo.isAuthenticated) {
      customConfigs.headers = { ...config.headers, Authorization: `Bearer ${authInfo.token}` }
    }
    // auto load token

    // auto trym value
    const httpMethod = config.method
    if (['post', 'put', 'patch'].includes(httpMethod || '')) {
      customConfigs.data = trymValues(config.data)
    }
    // auto trym value

    return customConfigs
  })
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: { 'Content-Type': 'application/json' },
})

interceptors(axiosInstance)

export { axiosInstance }
