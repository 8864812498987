import moment from 'moment'

const parseBeDate = (date?: string) => {
  if (!date) return null

  const parsedString = [date.slice(0, 4), date.slice(4, 6), date.slice(6, 8)].join('/')
  const momentDate = moment(parsedString, 'yyyy/MM/DD')

  return momentDate.isValid() ? momentDate : null
}

export { parseBeDate }
