import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { DEFAULT_LOADING_STATE, LOADING_STORE_NAME } from '../constants'
import { ILoadingState } from '../types/loadingState'

const loadingStore = createSlice({
  name: LOADING_STORE_NAME,
  initialState: DEFAULT_LOADING_STATE as ILoadingState,
  reducers: {
    showLoading: (state) => {
      state.loadingCount += 1
    },
    hideLoading: (state) => {
      if (state.loadingCount === 0) return

      state.loadingCount -= 1
    },
  },
})

const loadingActions = loadingStore.actions
const loadingReducer = loadingStore.reducer
const useLoadingState = () => {
  // @ts-expect-error
  const loadingState = useSelector((state) => state[LOADING_STORE_NAME])
  return loadingState as ILoadingState
}

export { loadingActions, loadingReducer, useLoadingState }
