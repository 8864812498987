import { Modal, Typography, ModalFuncProps } from 'antd'
import IconConfirm from 'assets/icons/confirm-icon.svg'
import IconError from 'assets/icons/icon-error.svg'
import IconWarning from 'assets/icons/warning-icon.svg'

interface ModalProps extends ModalFuncProps {
  iconShow?: string
}

export const ModalNotify = ({
  title,
  content,
  okText = 'Tiếp tục',
  cancelText = 'Quay lại',
  onCancel = () => {},
  type = 'confirm',
  iconShow,
  ...props
}: ModalProps) => {
  const typeModal = type !== 'confirm' ? 'info' : 'confirm'
  const modal = Modal[typeModal]({
    icon: null,
    wrapClassName: 'custom-popup-notify',
    centered: true,
    closable: false,
    maskClosable: true,
    okText,
    zIndex: 1500,
    content: (
      <>
        <div className="text-center">
          <img
            src={
              iconShow
                ? iconShow
                : type === 'error'
                ? IconError
                : type === 'warning'
                ? IconWarning
                : IconConfirm
            }
            alt="iconWarning"
            className="mb-24"
          />

          {title && (
            <Typography className="mb-8 text-title6-semibold text-neutral-800">{title}</Typography>
          )}
          <Typography className="text-body-regular text-neutral-800">{content}</Typography>
        </div>
      </>
    ),
    cancelText,
    onCancel,
    ...props,
  })
  return modal
}
