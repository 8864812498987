import './_mainLayout.less'
import React from 'react'

import { Layout } from 'antd'
import msbLogo from 'assets/images/msb-logo.svg'
import { Link, Outlet } from 'react-router-dom'

import Menu from './components/Menu'

const NoFooterLayout: React.FC = () => (
  <main className="main-layout">
    <Layout.Header className="header">
      <div className="header--container">
        <Link to="/">
          <img src={msbLogo} alt="msb-logo" className="header--logo" />
        </Link>

        <Menu />
      </div>
    </Layout.Header>

    <Layout.Content className="content">
      <div className="container">
        <Outlet />
      </div>
    </Layout.Content>
  </main>
)

export default React.memo(NoFooterLayout)
