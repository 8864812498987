import React from 'react'

import { minutesToMilliseconds } from 'date-fns'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: minutesToMilliseconds(10),
      staleTime: minutesToMilliseconds(3),
      refetchInterval: minutesToMilliseconds(3),
      refetchOnWindowFocus: true,
      retry: false,
    },
  },
})

const QueryProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {window.location.origin.includes('localhost') && (
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    )}

    {children}
  </QueryClientProvider>
)

export { queryClient }
export default React.memo(QueryProvider)
