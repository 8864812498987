import { PATH_NAMES } from 'constants/pathNames'

import { createSlice } from '@reduxjs/toolkit'
import { removePartnerChannelLocalData } from 'hooks/usePartnerChannelData'
import { queryClient } from 'providers/QueryProvider'
import { useSelector } from 'react-redux'

import { authStoreName, defaultAuthState, getAuthStateFromLocalStorage } from '../helpers'
import { IAuthState } from '../types/authStoreTypes'

const initialState = getAuthStateFromLocalStorage()

const authSlice = createSlice({
  name: authStoreName,
  initialState,
  reducers: {
    loginPhoneNumber: (state: IAuthState, { payload: userInfos }: { payload: IAuthState }) => {
      for (const key in userInfos) {
        state[key] = userInfos[key]
      }

      // lưu trạng thái vào storage
      localStorage.setItem(authStoreName, JSON.stringify(state))
    },
    reSendOtp: (state: IAuthState, { payload: otp_uuid }: { payload: string }) => {
      state.otp_uuid = otp_uuid

      // lưu trạng thái vào storage
      localStorage.setItem(authStoreName, JSON.stringify(state))
    },
    loginUser: (state: IAuthState, { payload: token }: { payload: string }) => {
      state.token = token
      state.isAuthenticated = true
      state.loginType = 'login'

      // lưu trạng thái vào storage
      localStorage.setItem(authStoreName, JSON.stringify(state))
    },
    logoutUser: (state: IAuthState) => {
      for (const key in defaultAuthState) {
        state[key] = defaultAuthState[key]
      }

      localStorage.setItem(authStoreName, JSON.stringify(state))
      queryClient.clear()
      removePartnerChannelLocalData()

      try {
        import('routes').then(({ history }) => {
          history.navigate('/')
        })
      } catch {}
    },
    axiosLogout: (state: IAuthState) => {
      for (const key in defaultAuthState) {
        state[key] = defaultAuthState[key]
      }

      localStorage.setItem(authStoreName, JSON.stringify(state))
      queryClient.clear()
      removePartnerChannelLocalData()

      try {
        import('routes').then(({ history }) => {
          const pathanme = history.location.pathname?.split('/')?.filter((item) => item)?.[0]
          ///homepage token expire thì k redirect đến login
          if (pathanme && !['san-pham-vay'].includes(pathanme)) {
            history.navigate({
              pathname: PATH_NAMES.LOGIN_PATH,
              search: `?prevPath=${history.location.pathname}`,
            })
          }
        })
      } catch {}
    },
  },
})

const authActions = authSlice.actions
const authReducer = authSlice.reducer
const useAuthState = () => {
  // @ts-expect-error
  const authState = useSelector((state) => state[authStoreName])
  return authState as IAuthState
}

export { authActions, authReducer, useAuthState }
