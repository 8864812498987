const HOME_AND_PRODUCT_PATHS = {
  PRODUCT_PATH: '/san-pham-vay/:product_code',
  LOAN_CALCULATE: '/tinh-toan-khoan-vay',
}

const BEFORE_LOGIN_PATHS = {
  LOGIN_PATH: '/dang-nhap',
  OTP_PATH: '/xac-thuc-otp',
}

const AFTER_LOGIN_PATHS = {
  CUSTOMER_PATH: '/thong-tin-khach-hang',
  MARRIED_PERSON_PATH: '/thong-tin-nguoi-hon-phoi',
  CONTACT_PERSON_PATH: '/thong-tin-nguoi-lien-he',
  LOAN_INFOS_PATH: '/thong-tin-khoan-vay',
  INCOME_INFOS_PATH: '/thong-tin-nguon-thu',
  COLLATERAL_PATH: '/thong-tin-tai-san',
  LOAN_LIST_PATH: '/danh-sach-vay',
  LOAN_DETAILS_PATH: '/chi-tiet-vay',
  ACCOUNT_PATH: '/thong-tin-ca-nhan',
  EDIT_PROFILE_PATH: '/chinh-sua-thong-tin-ca-nhan',
  GUIDE_PROVIDE_PROFILE_PATH: '/huong-dan-cung-cap-ho-so',
  PROVIDE_PROFILE_PATH: '/cung-cap-ho-so',
  VERIFY_PROFILE_PATH: '/kiem-tra-thong-tin-ho-so',
  POLICY_PATH: '/cac-dieu-khoan',
  SUBMIT_SUCCESS_PATH: '/dang-ky-thanh-cong',
  MAGNET_13: '/nghi-dinh-13',
  CONFIRM_HOUSE_HOLD: '/xac-nhan-tai-cap-house-hold',
}

const PATH_NAMES = {
  ...HOME_AND_PRODUCT_PATHS,
  ...BEFORE_LOGIN_PATHS,
  ...AFTER_LOGIN_PATHS,
}

export { PATH_NAMES }
