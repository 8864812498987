const LogoutIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16732 3.16683C3.9021 3.16683 3.64775 3.27219 3.46021 3.45972C3.27267 3.64726 3.16732 3.90161 3.16732 4.16683V15.8335C3.16732 16.0987 3.27267 16.3531 3.46021 16.5406C3.64775 16.7281 3.9021 16.8335 4.16732 16.8335H7.50065C7.86884 16.8335 8.16732 17.132 8.16732 17.5002C8.16732 17.8684 7.86884 18.1668 7.50065 18.1668H4.16732C3.54848 18.1668 2.95499 17.921 2.5174 17.4834C2.07982 17.0458 1.83398 16.4523 1.83398 15.8335V4.16683C1.83398 3.54799 2.07982 2.9545 2.5174 2.51691C2.95499 2.07933 3.54848 1.8335 4.16732 1.8335H7.50065C7.86884 1.8335 8.16732 2.13197 8.16732 2.50016C8.16732 2.86835 7.86884 3.16683 7.50065 3.16683H4.16732ZM12.8626 5.36209C13.1229 5.10174 13.545 5.10174 13.8054 5.36209L17.9721 9.52876C18.2324 9.78911 18.2324 10.2112 17.9721 10.4716L13.8054 14.6382C13.545 14.8986 13.1229 14.8986 12.8626 14.6382C12.6022 14.3779 12.6022 13.9558 12.8626 13.6954L15.8912 10.6668H7.50065C7.13246 10.6668 6.83398 10.3684 6.83398 10.0002C6.83398 9.63197 7.13246 9.3335 7.50065 9.3335H15.8912L12.8626 6.3049C12.6022 6.04455 12.6022 5.62244 12.8626 5.36209Z"
      fill="#F4600C"
    />
  </svg>
)

export { LogoutIcon }
