import { LOAN_PURPOSE_OPTIONS } from 'features/loanInfos/components/LoanInfosPage/components/LoanInfos/helpers/loanInfosOptions'
import { getLabelOptions } from 'helpers'
import moment from 'moment'
import { ILoanListItemBE } from 'types/backend'
import { num2Word, numericString } from 'utils/string'

const mapLoanStatus = (status: string) => {
  switch (status) {
    case 'DRAFT':
      return 'Đăng ký thông tin'
    case 'SUBMIT_LOAN_REQUEST':
    case 'SUBMIT_LOAN_APPLICATION':
    case 'ACCEPT_LOAN_REQUEST':
    case 'ACCEPT_LOAN_APPLICATION':
    case 'PROCESSING':
      return 'Tiếp nhận hồ sơ'
    case 'CLOSED':
      return 'Đã đóng'
    case 'NEED_ADDITIONAL_INFO':
      return 'Bổ sung hồ sơ'
    case 'WAITING_FOR_CONFIRM':
      return 'Chờ KH xác nhận'
    case 'DECLINED_TO_CONFIRM':
      return 'Từ chối xác nhận'
    case 'RM_COMPLETED':
    case 'OTHER_BPM_RISK':
      return 'Chờ phê duyệt'
    case 'BPM_RISK_APPROVE':
      return 'Đã phê duyệt'
    case 'DISBURSEMENT':
      return 'Đã giải ngân'
    default:
      return ''
  }
}

const mapLoanStatusCss = (status: string) => {
  switch (status) {
    case 'DRAFT':
    case 'CLOSED':
    case 'NEED_ADDITIONAL_INFO':
    case 'WAITING_FOR_CONFIRM':
    case 'OTHER_BPM_RISK':
    case 'DISBURSEMENT':
    case 'BPM_RISK_APPROVE':
      return `loan-item-status--${status}`
    case 'SUBMIT_LOAN_REQUEST':
    case 'SUBMIT_LOAN_APPLICATION':
    case 'ACCEPT_LOAN_REQUEST':
    case 'ACCEPT_LOAN_APPLICATION':
    case 'PROCESSING':
    case 'RM_COMPLETED':
      return 'loan-item-status--PROCESSING'
    default:
      return 'loan-item-status--default'
  }
}

const loanItemDecorator = (loanItem: ILoanListItemBE) => {
  const curent_journey_statuses = loanItem.loan_journey_statuses.length

  const returnValues = {
    uuid: loanItem.uuid,
    loan_code: loanItem.loan_code,
    status: mapLoanStatus(loanItem.status),
    request_time: loanItem.submit_loan_request_time
      ? moment(loanItem.submit_loan_request_time).format('DD/MM/yyyy')
      : '',
    curent_journey_statuses: curent_journey_statuses === 0 ? 0 : curent_journey_statuses - 1,
    loan_items: (loanItem.loan_application_items || []).map((item) => ({
      loan_purpose: getLabelOptions(LOAN_PURPOSE_OPTIONS, item.loan_purpose),
      loan_amount: item.loan_amount ? `${numericString(item.loan_amount)} đ` : '',
      loan_amount_text: item.loan_amount ? `(${num2Word(item.loan_amount)} đồng)` : '',
      loan_time: item.loan_time ? `${item.loan_time} tháng` : '',
    })),
  }

  return returnValues
}

const getJourneyStatuseDate = (loanItem: ILoanListItemBE) => {
  const getStepDate = (step: string) => {
    const journeyStatus = loanItem.loan_journey_statuses?.find(
      (loanJourneyStatuses) => loanJourneyStatuses.status === step
    )

    return journeyStatus ? moment(journeyStatus.created_at).format('DD/MM/yyyy') : '-'
  }

  return [
    getStepDate('REGISTER_INFORMATION'),
    getStepDate('RECEIVE'),
    getStepDate('EXPERTISE_APPROVE'),
    getStepDate('DISBURSEMENT'),
  ]
}

const allowToCreateNewLoan = (loanList?: ILoanListItemBE[]) => {
  if (!loanList) return true

  const notAllowStatus = [
    'DRAFT', //Nháp
    'PROCESSING', //Đang xử lý
    'NEED_ADDITIONAL_INFO', //Bổ sung HS vay
    'WAITING_FOR_CONFIRM', //Chờ xác nhận hồ sơ
    'DECLINED_TO_CONFIRM', //Từ chối xác nhận
    'SUBMIT_LOAN_REQUEST', //Gửi ĐNVV
    'SUBMIT_LOAN_APPLICATION', //Gửi HS vay
    'ACCEPT_LOAN_REQUEST', //Tiếp nhận ĐNVV
    'ACCEPT_LOAN_APPLICATION', //Tiếp nhận HS vay
  ]

  const drafLoan = loanList?.some((loan) => notAllowStatus.includes(loan.status))
  return !drafLoan
}

export {
  allowToCreateNewLoan,
  getJourneyStatuseDate,
  loanItemDecorator,
  mapLoanStatus,
  mapLoanStatusCss,
}
