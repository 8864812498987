import { getFirstError } from 'helpers/apiHelper'
import _ from 'lodash'

import { LOGIN_ERROR_MESSSAGES } from '../constants'

// internal start
const getBlockTimeFormMsg = (msg: any) => {
  const limitSecond = parseInt(_.first(msg.match(/\d+/)) as string)
  return limitSecond
}

const getBlockTimeMsg = (msg: any) => {
  const limitSecond = parseInt(_.first(msg.match(/\d+/)) as string)
  const limitMinute = (limitSecond / 60).toString()

  return LOGIN_ERROR_MESSSAGES.BLOCK_SEND_OTP.replace('%s', limitMinute)
}
// internal end

const getOtpErrorMsg = (error: any) => {
  const { code, message } = getFirstError(error)

  switch (code) {
    case 406001:
      return getBlockTimeMsg(message)
    case 400005:
      return LOGIN_ERROR_MESSSAGES.GEN_OTP_ERROR
    default:
      return message
  }
}

const getBlockTime = (error: any) => {
  const { code, message } = getFirstError(error)

  switch (code) {
    case 406001:
      return getBlockTimeFormMsg(message)
    default:
      return null
  }
}

const isWrongOtpManyTime = (error: any) => {
  const { code } = getFirstError(error)
  return code === 400011
}

const getConfirmErrorMsg = (error: any) => {
  const { code, message } = getFirstError(error)

  switch (code) {
    case 400012:
    case 400008:
      return LOGIN_ERROR_MESSSAGES.EXPRIED_OTP
    case 400004:
      return LOGIN_ERROR_MESSSAGES.WRONG_OTP
    case 400011:
      return LOGIN_ERROR_MESSSAGES.WRONG_OTP_MAX_TIMES
    default:
      return message
  }
}

export { getOtpErrorMsg, getBlockTime, isWrongOtpManyTime, getConfirmErrorMsg }
