import { useEffect, useMemo } from 'react'

import { PATH_NAMES } from 'constants/pathNames'

import { useGlobalLoading } from 'hooks/useGlobalLoading'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { ILoanListItemBE } from 'types/backend'

import { allowToCreateNewLoan } from '../helpers'
import { getLoanList } from '../services'

const useLoanList = () => {
  const navigate = useNavigate()
  const query = useQuery({
    queryKey: ['use-loan-list'],
    queryFn: getLoanList as unknown as () => ILoanListItemBE[],
    placeholderData: [],
  })

  const allowCreate = useMemo(() => allowToCreateNewLoan(query.data), [query.data])
  useGlobalLoading(!query.isFetched && query.isFetching)
  useEffect(() => {
    if (!query.isFetched || query.isFetching || query.data?.length !== 0) return // chua fetch xong data hoac xong va co data

    navigate(PATH_NAMES.CUSTOMER_PATH)
  }, [query.isFetched, query.isFetching])

  return { ...query, allowCreate }
}

export { useLoanList }
