import { PATH_NAMES } from 'constants/pathNames'

import { IHomepage } from 'features/homepages/types'

const parseHeaderData = (data?: IHomepage) => ({
  products:
    data?.products?.cardProduct
      ?.filter((i) => i.isShow)
      ?.map((i) => ({
        label: i.cardTitle,
        key: PATH_NAMES.PRODUCT_PATH.replace(':product_code', i.productCode || ''),
      })) || [],
})

export { parseHeaderData }
