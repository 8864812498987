import { useQuery } from 'react-query'

import { getMagnetData } from '../services/magnetServices'
import { useAuthState } from '../stores'

const useMagnetData = (options?: { showOnly?: boolean }) => {
  const { phone, isAuthenticated } = useAuthState()

  const query = useQuery({
    queryKey: ['magnet', phone, options?.showOnly ? 'showOnly' : ''],
    queryFn: async () => {
      try {
        return (await getMagnetData(options?.showOnly ? '' : phone)).data
      } catch {
        return undefined
      }
    },
    enabled: Boolean(phone) && isAuthenticated,
    onError: () => {},
    retry: false,
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
    refetchInterval: Number.POSITIVE_INFINITY,
  })

  return query
}

export { useMagnetData }
