/* eslint-disable no-restricted-globals */
import './_styles.less'
import { FC, Fragment, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { useBoolean, useLocalStorageState } from 'ahooks'
import { Form, Modal, Space, Typography } from 'antd'
import IconWarning from 'assets/icons/warning-icon.svg'
import { useAccountAdviseInfos } from 'features/adviseModal/hooks/useAccountAdviseInfos'
import { useAuthState } from 'features/authentication'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { ModalNotify } from 'utils/modal'

import { AdviseForm } from './components/AdviseForm'

const AdviseProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation()
  const [isCheckedAdvise, setIsCheckedAdvise] = useState(false)
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] = useBoolean()
  const [form] = Form.useForm()

  const [shownAdviseModal, setShownAdviseModal] = useLocalStorageState<number | undefined>(
    'ADVISE_SHOWN_MODAL',
    {
      defaultValue: 0,
    }
  )

  const [canShowAdviseModal, setCanShowAdviseModal] = useLocalStorageState<boolean | undefined>(
    'CAN_SHOW_ADVISE_MODAL',
    {
      defaultValue: false,
    }
  )

  const [isBlockAdvise] = useLocalStorageState<boolean | undefined>('IS_USER_BLOCK_ADVISE', {
    defaultValue: false,
  })

  const { isAuthenticated } = useAuthState()

  const { data: accountAdviseInfos, isFetched: accountAdviseInfosFetched } = useAccountAdviseInfos()
  const isAccountHadLead = useMemo(
    () => _.get(accountAdviseInfos, 'data.message') as boolean | undefined,
    [accountAdviseInfos]
  )

  useEffect(() => {
    // const log = process.env.NODE_ENV === 'development' ? console.log : undefined
    // log?.('Run Effect')
    if (pathname !== '/') return // log?.('Chỉ thực hiện check advise ở trang chủ')
    if (isCheckedAdvise) return // log?.('Đã check. Bỏ qua hook')
    if (isAuthenticated && !accountAdviseInfosFetched) return // log?.('Đợi fetch data')
    if (isAuthenticated && isAccountHadLead) {
      return // log?.('Khách hàng đã có lead trên hệ thống. Bỏ qua hook')
    }

    // log?.('Khách hàng chưa có lead trên hệ thống')
    // @ts-expect-error
    if (!isBlockAdvise && canShowAdviseModal && shownAdviseModal < 2) {
      // log?.('Thuc hien show modal')
      openModal()
    }

    setCanShowAdviseModal(true) // đây là lần đầu set, lần thứ 2 sẽ show modal
    setIsCheckedAdvise(true)
  }, [accountAdviseInfosFetched, isAccountHadLead, pathname])

  const onCloseModal = () => {
    // @ts-expect-error
    setShownAdviseModal((prev) => prev + 1)
    console.log('sett')
    closeModal()
  }

  const closeWithConfirm = useCallback(async () => {
    if (!form.isFieldsTouched()) return onCloseModal()
    try {
      await form.validateFields()
      return onCloseModal()
    } catch {
      const hasError = form.getFieldsError()?.some((e) => e.errors.length !== 0)
      if (hasError) {
        ModalNotify({
          type: 'confirm',
          title: 'Thông tin chưa được gửi',
          content: 'Bạn chưa gửi thông tin tư vấn. Bạn có muốn tiếp tục hoàn thiện không?',
          cancelText: 'Bỏ qua',
          okText: 'Đồng ý',
          iconShow: IconWarning,
          onCancel: onCloseModal,
          onOk: () => {},
        })
      }
    }
  }, [])

  return (
    <Fragment>
      {children}
      <Modal
        title="Hẹn thời gian tư vấn"
        open={isModalOpen}
        className="advise-modal"
        closable={false}
        onCancel={closeWithConfirm}
        destroyOnClose
      >
        <Space className="tw-w-full" size={16} direction="vertical">
          <Typography className="tw-text-h3-bold tw-font-Inter">
            Bạn vẫn đang tìm kiếm gói vay phù hợp?
          </Typography>

          <Typography className="tw-font-Inter tw-text-base-regular">
            Vui lòng gửi thông tin cho chúng tôi để được tư vấn gói vay phù hợp nhất cho bạn
          </Typography>

          <AdviseForm form={form} closeWithConfirm={closeWithConfirm} closeModal={onCloseModal} />
        </Space>
      </Modal>
    </Fragment>
  )
}

export { AdviseProvider }
