import { loadingActions } from 'features/globalLoading'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { openNotification } from 'utils/notification'

import { getDataLoanCalculate, getLoanConfigs } from '../service'

const useLoanCalculateConfigs = () =>
  useQuery({
    queryKey: ['loan-calculate-configs'],
    queryFn: () => getLoanConfigs(),
    retry: false,
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
    refetchInterval: Number.POSITIVE_INFINITY,
  })

const useGetDataLoanCalculate = () => {
  const dispatch = useDispatch()
  return useMutation(getDataLoanCalculate, {
    onMutate: () => {
      dispatch(loadingActions.showLoading())
    },
    onSuccess: () => {},
    onSettled: () => {
      dispatch(loadingActions.hideLoading())
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: 'Lỗi hệ thống. Vui lòng thử lại sau!',
      })
    },
  })
}

export { useLoanCalculateConfigs, useGetDataLoanCalculate }
