const MailIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.24209 7.09082C2.21433 7.26876 2.19995 7.45094 2.19995 7.63636V16.3636C2.19995 18.3719 3.88749 20 5.96919 20H18.0307C20.1124 20 21.8 18.3719 21.8 16.3636V15.8788C21.8 15.4771 21.4624 15.1515 21.0461 15.1515C20.6298 15.1515 20.2923 15.4771 20.2923 15.8788V16.3636C20.2923 17.5686 19.2798 18.5455 18.0307 18.5455H5.96919C4.72017 18.5455 3.70765 17.5686 3.70765 16.3636V8.13524L11.3215 13.5612C11.5756 13.7422 11.9218 13.7422 12.1758 13.5612L20.2923 7.77709V12.4849C20.2923 12.8865 20.6298 13.2121 21.0461 13.2121C21.4624 13.2121 21.8 12.8865 21.8 12.4849V7.63636C21.8 5.62806 20.1124 4 18.0307 4H5.96919C4.11959 4 2.58115 5.28526 2.26109 6.98054L2.19995 7.06078L2.24209 7.09082ZM3.97934 6.59862L11.7487 12.1354L19.8609 6.3543C19.4498 5.80901 18.7832 5.45454 18.0307 5.45454H5.96919C5.10955 5.45454 4.36194 5.91726 3.97934 6.59862Z"
      fill="#F4600C"
    />
  </svg>
)

export { MailIcon }
