import _ from 'lodash'

type T_ReturnOptions = { label: string; value: string; title?: string }[] & {
  getLabel: (value: string | null | undefined) => string
}

declare global {
  interface ObjectConstructor {
    createSelectOptions<T extends object>(
      configs: T
    ): {
      options: T_ReturnOptions
      code: { [P in keyof T]: string }
    }
  }
}

if (!Object.createSelectOptions) {
  Object.createSelectOptions = (configs) => {
    const options = Object.keys(configs).map((key) =>
      typeof configs[key] === 'object'
        ? {
            ...configs[key],
            value: key,
          }
        : {
            label: configs[key],
            value: key,
          }
    ) as T_ReturnOptions

    options.getLabel = (value) => options?.find((item) => item.value === value)?.label || ''

    return {
      options,
      code: _.mapValues(configs, (__, key) => key),
    }
  }
}

export {}
