import { useCallback } from 'react'

import { Form, FormInstance } from 'antd'
import { useDistrictListQuery } from 'hooks/useDistrictList'
import { useProvinceListQuery } from 'hooks/useProvinceList'
import { useWardListQuery } from 'hooks/useWardtList'

const useAddressSelect = (
  form: FormInstance,
  options: {
    province: string
    district: string
    ward: string
  } = {
    province: 'province',
    district: 'district',
    ward: 'ward',
  }
) => {
  const { province, district, ward } = options
  const provinceCode = Form.useWatch(province, form)
  const districtCode = Form.useWatch(district, form)
  const provinceListQuery = useProvinceListQuery()
  const districtListQuery = useDistrictListQuery(provinceCode)
  const wardListQuery = useWardListQuery(provinceCode, districtCode)

  const onProvinceChange = useCallback(
    (value: any) => {
      const provinceObject = provinceListQuery.data?.find((p) => p.code === value)

      form.setFieldsValue({
        [`${province}_name`]: provinceObject?.name,
        [district]: null,
        [`${district}_name`]: '',
        [ward]: null,
        [`${ward}_name`]: '',
      })
    },
    [provinceListQuery]
  )

  const onDistrictChange = useCallback(
    (value: any) => {
      const districtObject = districtListQuery.data?.find((d) => d.code === value)

      form.setFieldsValue({
        [`${district}_name`]: districtObject?.name,
        [ward]: null,
        [`${ward}_name`]: '',
      })
    },
    [districtListQuery]
  )

  const onWardChange = useCallback(
    (value: any) => {
      const wardObject = wardListQuery.data?.find((w) => w.code === value)

      form.setFieldsValue({
        [`${ward}_name`]: wardObject?.name,
      })
    },
    [wardListQuery]
  )

  return {
    provinceCode,
    districtCode,
    provinceList: provinceListQuery.data,
    districtList: districtListQuery.data,
    wardList: wardListQuery.data,
    provinceLoading: provinceListQuery.isFetching || provinceListQuery.isLoading,
    districtLoading: districtListQuery.isFetching || districtListQuery.isLoading,
    wardLoading: wardListQuery.isFetching || wardListQuery.isLoading,
    onProvinceChange,
    onDistrictChange,
    onWardChange,
  }
}

export { useAddressSelect }
