import './polifills/String.replaceAll'
import './v2/polyfills/string'
import './v2/polyfills/number'
import './v2/polyfills/object'
import 'styles/index.less'
import 'styles/index.css'

import React from 'react'

import ReactDOM from 'react-dom'

import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
