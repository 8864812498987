import React from 'react'

import { PATH_NAMES } from 'constants/pathNames'

import { Button, Grid, Typography } from 'antd'
import subtractIcon from 'assets/icons/subtract.svg'
import Breadcrumb from 'components/Breadcrumb'
import PersonInfoCard from 'components/PersonInfoCard'
import { Link } from 'react-router-dom'

import { useProfile } from '../../hooks/useProfile'

const AccountPage: React.FC = () => {
  const { data: profile, isFetching, isFetched } = useProfile()
  const screens = Grid.useBreakpoint()

  if (!isFetched && isFetching) return <></>

  return (
    <>
      <Breadcrumb items={['Trang chủ', 'Thông tin cá nhân']} />
      <Typography className="mb-16 text-common-title">Thông tin khách hàng</Typography>

      <PersonInfoCard
        title="Thông tin cá nhân"
        rightHeader={
          <Link to={PATH_NAMES.EDIT_PROFILE_PATH}>
            <Button type="text" className="text-link" shape={screens.md ? undefined : 'circle'}>
              <img src={subtractIcon} alt="subtractIcon" />
              {screens.md && <span className="ml-8">Thay đổi</span>}
            </Button>
          </Link>
        }
        person={profile?.data}
        includeRefCode={false}
      />
    </>
  )
}

export default React.memo(AccountPage)
