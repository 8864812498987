import { Radio, Select } from 'antd'
import DocIcon from 'assets/icons/doc-icon.svg'
import ImageIcon from 'assets/icons/image-icon.svg'
import PdfIcon from 'assets/icons/pdf-icon.svg'
import _ from 'lodash'
import { INullable, IOption } from 'types'
import { parseBeDate } from 'utils/date'
import { ModalNotify } from 'utils/modal'
import { unsignedVietnamese } from 'utils/string'

const selectFilterOption = (input: string, option: any) => {
  try {
    const optionLabel = (_.get(option, 'children') || _.get(option, 'label'))
      ?.toString()
      ?.toLowerCase()
    const unsignedOptionLabel = unsignedVietnamese(optionLabel)
    const unsignedInputString = unsignedVietnamese(input.toLowerCase())

    return unsignedOptionLabel.includes(unsignedInputString)
  } catch {
    return false
  }
}

const getLabelOptions = (option: IOption[], value: INullable<string>) =>
  option?.find((item) => item.value === value)?.label || ''

const trymValues = (values: any): any => {
  if (!values) return values

  try {
    switch (typeof values) {
      case 'string':
        return values.trim()
      case 'object':
        return Array.isArray(values)
          ? values.map((val: any) => trymValues(val))
          : _.mapValues(values, (val: any) => trymValues(val))
      default:
        return values
    }
  } catch {
    return values
  }
}

const renderSelectOptions = (options: IOption[]) =>
  options.map((option: IOption, index: number) => (
    <Select.Option value={option.value} key={index}>
      {option.label}
    </Select.Option>
  ))

const renderRadioOptions = (options: IOption[]) =>
  options.map((option: IOption, index: number) => (
    <Radio value={option.value} key={index}>
      {option.label}
    </Radio>
  ))

const formatBEDate = (date: string) => (date ? parseBeDate(date)?.format('DD/MM/yyyy') : '')

const getTypeFile = (filename: string = '') => {
  const fileType = filename.split('.').pop() || ''

  if (['doc', 'docx'].includes(fileType)) {
    return {
      type: 'DOC',
      icon: DocIcon,
    }
  } else if (['pdf'].includes(fileType)) {
    return {
      type: 'PDF',
      icon: PdfIcon,
    }
  } else if (['png'].includes(fileType)) {
    return {
      type: 'IMAGE',
      icon: ImageIcon,
    }
  } else {
    return {
      type: 'IMAGE',
      icon: ImageIcon,
      'content-type': {
        'Content-type': 'image/jpeg',
      },
    }
  }
}

const crateLinkDownload = (blob: any, nameFile: string) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = nameFile
  link.click()
  if (link.parentNode != null) link.parentNode.removeChild(link)
}
// create file blob then createLinkDownload
const createFileDownload = async (nameFile: string, data: any, type: string = '') => {
  const blob = new Blob([data], { type })
  crateLinkDownload(blob, nameFile)
}

const fileTypeAccept = (files: any[]) => {
  const result = files.every(
    (fileType) =>
      String(fileType).includes('image/jpeg') ||
      String(fileType).includes('image/png') ||
      String(fileType).includes('pdf') ||
      String(fileType).includes(
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) ||
      String(fileType).includes('application/msword')
  )

  !result &&
    ModalNotify({
      title: 'Lỗi tải ảnh',
      content: 'Vui lòng tải lên file định dạng ảnh hoặc pdf hoặc doc/docx.',
    })

  return result
}

const fileSizeAccept = (files: any[]) => {
  const result = files.every((fileSize) => fileSize < 10000000)
  !result &&
    ModalNotify({
      title: 'Lỗi tải ảnh',
      content: 'Vui lòng tải lên file có dung lượng không quá 10MB',
    })
  return result
}

export {
  selectFilterOption,
  getLabelOptions,
  trymValues,
  formatBEDate,
  getTypeFile,
  renderSelectOptions,
  renderRadioOptions,
  createFileDownload,
  fileTypeAccept,
  fileSizeAccept,
}
