import { PATH_NAMES } from 'constants/pathNames'

import { HomePage, Products } from 'features/homepages'
import LoanCalculate from 'features/homepages/pages/LoanCalculate'
import HomeAndProductLayout from 'layouts/HomeAndProductLayout'

const homeAndProductsRoutes = {
  path: '/',
  element: <HomeAndProductLayout />,
  children: [
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: PATH_NAMES.PRODUCT_PATH,
      element: <Products />,
    },
    {
      path: PATH_NAMES.LOAN_CALCULATE,
      element: <LoanCalculate />,
    },
  ],
}

export { homeAndProductsRoutes }
