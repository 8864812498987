const DownIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.43427 8.43427C5.74669 8.12185 6.25322 8.12185 6.56564 8.43427L12 13.8686L17.4343 8.43427C17.7467 8.12185 18.2532 8.12185 18.5656 8.43427C18.8781 8.74669 18.8781 9.25322 18.5656 9.56564L12.5656 15.5656C12.2532 15.8781 11.7467 15.8781 11.4343 15.5656L5.43427 9.56564C5.12185 9.25322 5.12185 8.74669 5.43427 8.43427Z"
      fill="#667085"
    />
  </svg>
)

export { DownIcon }
