import { PATH_NAMES } from 'constants/pathNames'

import { EditAccountPage } from 'features/accountInfos'
import AccountPage from 'features/accountInfos/components/AccountPage'
import { Magnetpage } from 'features/authentication/components/Magnetpage'
import { CollateralPage } from 'features/collateralInfos'
import { CustomerInfosPage, ContactPersonPage, MarriedPersonPage } from 'features/customerInfos'
import { IncomeInfosPage } from 'features/incomeInfos'
import { LoanDetailsPage } from 'features/loanDetails'
import { LoanInfosPage } from 'features/loanInfos'
import { LoanListPage } from 'features/loanList'
import { PolicyPage } from 'features/policy'
import {
  GuideProvidePage,
  ProvideProfilePage,
  VerifyProfilePage,
} from 'features/provideProfile/routes'
import { SubmitSuccessPage } from 'features/submitSuccess'
import { MainLayout } from 'layouts'
import { Navigate } from 'react-router-dom'

const afterLoginRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: PATH_NAMES.CUSTOMER_PATH,
      element: <CustomerInfosPage />,
    },
    {
      path: PATH_NAMES.MARRIED_PERSON_PATH,
      element: <MarriedPersonPage />,
    },
    {
      path: PATH_NAMES.CONTACT_PERSON_PATH,
      element: <ContactPersonPage />,
    },
    {
      path: PATH_NAMES.LOAN_INFOS_PATH,
      element: <LoanInfosPage />,
    },
    {
      path: PATH_NAMES.INCOME_INFOS_PATH,
      element: <IncomeInfosPage />,
    },
    {
      path: PATH_NAMES.COLLATERAL_PATH,
      element: <CollateralPage />,
    },
    {
      path: PATH_NAMES.GUIDE_PROVIDE_PROFILE_PATH,
      element: <GuideProvidePage />,
    },
    {
      path: PATH_NAMES.PROVIDE_PROFILE_PATH,
      element: <ProvideProfilePage />,
    },
    {
      path: PATH_NAMES.VERIFY_PROFILE_PATH,
      element: <VerifyProfilePage />,
    },

    {
      path: PATH_NAMES.LOAN_LIST_PATH,
      element: <LoanListPage />,
    },
    {
      path: PATH_NAMES.ACCOUNT_PATH,
      element: <AccountPage />,
    },
    {
      path: PATH_NAMES.EDIT_PROFILE_PATH,
      element: <EditAccountPage />,
    },
    {
      path: PATH_NAMES.LOAN_DETAILS_PATH,
      element: <LoanDetailsPage />,
    },
    {
      path: PATH_NAMES.POLICY_PATH,
      element: <PolicyPage />,
    },
    {
      path: PATH_NAMES.SUBMIT_SUCCESS_PATH,
      element: <SubmitSuccessPage />,
    },
    {
      path: PATH_NAMES.MAGNET_13,
      element: <Magnetpage />,
    },
    {
      path: '*',
      element: <Navigate to={PATH_NAMES.LOAN_LIST_PATH} />,
    },
  ],
}

export { afterLoginRoutes }
