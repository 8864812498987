import { AxiosError } from 'axios'
import _ from 'lodash'
import { IApiError } from 'types/backend'

const getFirstError = (error: AxiosError) => {
  const defaultError: IApiError = { code: undefined, message: '' }
  try {
    // @ts-expect-error
    const errorObject = _.get(error, 'response.data.errors[0]') as IApiError
    return errorObject || defaultError
  } catch {
    return defaultError
  }
}

export { getFirstError }
