export const TYPE_BUTTON = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
}

export const CODE_BUTTON = {
  DANG_KY_TU_VAN: 'DANG_KY_TU_VAN',
  DANG_KY_NGAY: 'DANG_KY_NGAY',
  KHAM_PHA_NGAY: 'KHAM_PHA_NGAY',
}

export const ADVISE = {
  CUSTOMER_NAME: 'CUSTOMER_NAME',
  CUSTOMER_PHONE: 'CUSTOMER_PHONE',
  PRODUCT: 'PRODUCT',
  PROVINCE_CODE: 'PROVINCE_CODE',
}
