import { Breadcrumb } from 'antd'
import rightIcon from 'assets/icons/right.svg'
import { Link } from 'react-router-dom'
import './_style.less'

export const BreadcrumbLoanCalculate = () => (
  <Breadcrumb
    separator={<img src={rightIcon} alt="rightIcon" />}
    className="loan-calculate--breadcrumb"
  >
    <Breadcrumb.Item key={'trang-chu'}>
      <Link to={'/'}>Trang chủ</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item key={'du-tru-tai-chinh'}>Dự trù tài chính</Breadcrumb.Item>
  </Breadcrumb>
)
