import './_styles.less'
import React, { useEffect, useRef } from 'react'

import { useSize } from 'ahooks'
import { Layout } from 'antd'
import _ from 'lodash'
import { Outlet } from 'react-router-dom'

import Footer from './_Footer'
import Header from './_Header'

const HomeAndProductLayout: React.FC = () => {
  const header = useRef(null)
  const footer = useRef(null)
  const headerSize = useSize(header)
  const footerSize = useSize(footer)

  useEffect(() => {
    // useSize ra kết quả sấp sỉ nên cần tính lại size thủ công
    // useSize chỉ đóng vai trò trigger re-render nếu có sự thay đổi của header và footer
    try {
      const headerH =
        _.get(document.querySelector('#home-and-products .header'), 'offsetHeight') || 0
      const footerH =
        _.get(document.querySelector('#home-and-products .footer'), 'offsetHeight') || 0
      const content = document.querySelector('#home-and-products .content')
      // @ts-expect-error
      content.style.minHeight = `calc(100vh - ${headerH + footerH}px)`
    } catch {}
  }, [headerSize?.height, footerSize?.height])

  return (
    <Layout id="home-and-products">
      <Layout.Header className="header" ref={header}>
        <Header />
      </Layout.Header>

      <Layout.Content className="content">
        <Outlet />
      </Layout.Content>

      <Layout.Footer className="footer" ref={footer}>
        <Footer />
      </Layout.Footer>
    </Layout>
  )
}

export default React.memo(HomeAndProductLayout)
