import { axiosInstance } from 'services/axiosInstance'
import { ILoanListItemBE } from 'types/backend'
import { TSuccessResponse } from 'types/comonApi'

const getLoanList = async (): Promise<TSuccessResponse<ILoanListItemBE[]>> => {
  try {
    const res = await axiosInstance.get('/home-loan-be/api/v1/ldp/loan-applications/list')
    return res.data
  } catch (error) {
    throw error
  }
}

export { getLoanList }
