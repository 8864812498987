import { axiosInstance } from 'services/axiosInstance'
import { ILoanApplicationBE } from 'types/backend'
import { TSuccessResponse } from 'types/comonApi'

const getProfile = (profileId: string): Promise<TSuccessResponse<ILoanApplicationBE>> =>
  axiosInstance.get(`/home-loan-be/api/v1/ldp/profile?profileId=${profileId}`)

const updateProfile = (payload: any) =>
  axiosInstance.put('/home-loan-be/api/v1/ldp/profile', payload)

export { getProfile, updateProfile }
