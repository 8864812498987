import { useAuthState } from 'features/authentication'
import { useGlobalLoading } from 'hooks/useGlobalLoading'
import { useQuery } from 'react-query'

import { getProfile } from '../services'

const useProfile = (enabled?: boolean) => {
  const { uuid, isAuthenticated } = useAuthState()

  const query = useQuery({
    queryKey: ['use-profile', uuid],
    queryFn: () => getProfile(uuid),
    enabled: Boolean(uuid) && isAuthenticated && enabled,
  })

  useGlobalLoading(!query.isFetched && query.isFetching)
  return query
}

export { useProfile }
